import { Grid, Typography } from "@mui/material";
import { usePermissions } from "react-admin";
import { LoadingAnimation } from "./LoadingAnimation";
import { ScalePermissions } from "../Main";

export const useScalePermissions = (): { permissions: ScalePermissions, isLoading: boolean, error: any } => {
  const { permissions, isLoading, error } = usePermissions();
  return {
    permissions: permissions as ScalePermissions,
    isLoading,
    error
  };
};

export const withIdentityChecks = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const { permissions, isLoading } = useScalePermissions();
    if (isLoading) return <LoadingAnimation loadingText='Loading identity.' />

    if (permissions.isUnverified) {
      return <Grid container direction='row' justifyContent='center'>
        <Grid item sm={5}>
          <Typography variant="h4">Welcome to Scale SaaS!</Typography>
          <Typography variant="body1"><p>We've sent you an email verification email. Please click the link on it to complete your sign up.</p></Typography>
          <Typography variant="body1"><p>If you cannot find the email or it is expired, please drop us a line at <a href="mailto:support@scale-company.com">support@scale-company.com</a> or chat for support.</p></Typography>
        </Grid>
        <Grid item sm={2}>
          <img alt="Email sent!" src="https://storage.googleapis.com/scale_bucket-1/undraw_subscriber_re_om92.svg" width={200} />
        </Grid>
      </Grid>
    }

    if (permissions.hasUnknownOrganization) {
      return <Grid container direction='row' justifyContent='center'>
        <Grid item sm={5}>
          <Typography variant="h4">Thank you for signing up for early access!</Typography>
          <Typography variant="body1">We have received your request and we will contact you as soon as we can onboard your organization into the platform.</Typography>
          <Typography variant="body1">If your organization is already using Scale please contact us by email <a href="mailto:support@scale-company.com">support@scale-company.com</a> or chat for support.</Typography>
        </Grid>
        <Grid item sm={2}>
          <img alt="Thank you for signing!" src="https://storage.googleapis.com/scale_bucket-1/undraw_well_done_re_3hpo.svg" width={200} />
        </Grid>
      </Grid>
    }

    // If checks pass, render the component with its props
    return <Component {...props} />;
  };
};