import { CurrencyFormat } from "../configuration/configuration";
import currency from "currency.js";
import { isNotNil } from "./ScaleUtils";

export const defaultCurrencyFormatter = (value: number) => {
  return currency(value, CurrencyFormat).format();
};

export const formatToPercentage = (num: number): string => {
  return `${(num*100).toFixed(1)}%`;
};


const finnishDateFormatter = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

export const formatDate = (s?: string): string =>
  isNotNil(s) ? finnishDateFormatter.format(new Date(s)) : ''
