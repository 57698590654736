import React, { Fragment } from 'react';

export type Rectangle = {
  x: number,
  y: number,
  width: number,
  height: number
};

function overlaps(a: Rectangle, b: Rectangle): boolean {
  return a.x < b.x + b.width && a.x + a.width > b.x && a.y < b.y + b.height && a.y + a.height > b.y;
}

/**
 * Packs rectangles on different arrays where they will not overlap,
 * if they overap on the original array in some way. 
 * @param rectangles array of rectangles 
 * @returns 
 */
export function packRectangles<T extends Rectangle>(inputRectangles: T[]): T[][] {
  const rectangles = [...inputRectangles].sort((a, b) => a.x - b.x);

  const rows: T[][] = [];

  for (let rectangle of rectangles) {
    let placed = false;
    for (let row of rows) {
      if (!row.some(r => overlaps(r, rectangle))) {
        row.push(rectangle);
        placed = true;
        break;
      }
    }

    if (!placed) {
      rows.push([rectangle]);
    }
  }

  return rows;
}

/**
 * Converts text with line breaks into JSXElement with paragraphs <p>
 * @param text text to be split to paragraphs
 * @returns 
 */
export function toParagraphs(text: string | undefined): JSX.Element {
  if (typeof text !== 'string') {
    return <></>;
  }
  const lines = text.split("\n").filter(line => line.trim().length > 0);
  if (lines.length === 0) return <></>;
  return (
    <Fragment>
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </Fragment>
  );
};

/**
 * Estimate Text Width of given text
 * @param text text to estimate
 * @param fontSize size used as 
 * @returns estimated width in pixels
 */
export function estimateTextWidth(text: string, fontSize: number): number {
  //  return measureText(text, "" + fontSize + "pt", "Open Sans");
  const averageCharacterSizeFactor = 0.55; // an estimation
  return text.length * fontSize * averageCharacterSizeFactor;
}

export function measureText(text: string, fontSize: string, fontFamily: string): number {
  let canvas = document.createElement('canvas');
  let context = canvas.getContext("2d")!;

  context.font = `${fontSize} ${fontFamily}`;
  return context.measureText(text).width;

}

/**
 * Converts SVG string to Data URI
 * @param svgContent the svg string
 * @returns uri that contains the base64 encoded svg content
 */
export function convertSVGImageToDataURI(svgContent: string): string {
  return 'data:image/svg+xml;base64,' + btoa(svgContent);
}

/**
 * Saves blob as file.
 * @param blob 
 * @param fileName 
 */
export function saveAs(blob: any, fileName: string) {
  console.log("Save as");
  const elem = window.document.createElement('a');
  elem.href = blob
  elem.download = fileName;
  //@ts-ignore
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }
  URL.revokeObjectURL(elem.href);
  elem.remove()
}



/*
export const fetchSvgUrlAsPngBuffer = async (svgUrl: string, width?: number, height?: number): Promise<Buffer> => {
  const response = await fetch(svgUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch SVG: ${response.statusText}`);
  }
  const svgString = await response.text();
  return new Promise((resolve, reject) => {
    const options: any = {};
    if (width) options.width = width;
    if (height) options.height = height;

    svg2img(svgString, options, (error: Error | null, buffer: Buffer) => {
      if (error) {
        return reject(error);
      }
      resolve(buffer);
    });
  });
};
*/