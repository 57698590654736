import { Grid, Switch } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import React, { ChangeEvent, useContext } from 'react';
import { BusinessHistory } from '../model/ScaleTypes';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { AnimatedPortfolioBubbleChart } from '../components/charts/AnimatedPortfolioBubbleChart';
import { BusinessHistoryGraph } from '../components/charts/BusinessHistoryGraph';
import { useGetList } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { SettingsContext } from '../state/SettingsContext';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import useMaturityModel from '../state/useMaturityModel';

const PortfolioHistoryView = (props: any) => {

  const settings = useContext(SettingsContext);
  const { permissions, isLoading } = useScalePermissions();
  const { maturityModel, loading: loadingMaturityModel } = useMaturityModel();

  const [showDaysFromStart, setShowDaysFromStart] = React.useState<boolean>(false);
  const handleSwitchDaysFromStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDaysFromStart(event.target.checked);
  };

  const { data, isLoading: loading } = useGetList(
    'api_businessHistoryWithScore', {
    pagination: { page: 1, perPage: 10000 },
    filter: {
      "deleted@_neq": true,
      "businessId@_in": permissions.isLimitedAccess ? permissions.allowedBusinessIds : undefined
    }
  },
  );//, { field: "scoreModifiedAt", order: 'desc'});

  if (loading || loadingMaturityModel || isLoading) {
    return <LoadingAnimation loadingText='Loading history view' />
  }

  if (!maturityModel) return <p>Failure loading maturityModel.</p>

  const dataInArray: BusinessHistory[] = data?.filter(business => business.status !== 'removed') as BusinessHistory[];

  //@ts-ignore
  return (
    <Grid container>
      <Grid item xs={4} sm={4} md={4}></Grid>
      <Grid item xs={4} sm={4} md={4}></Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Typography textAlign="right">Show demo data: <Switch
          checked={settings.interfaceSettings.demoMode}
          onChange={(event: ChangeEvent<HTMLInputElement>) => settings.setInterfaceSetting('demoMode', event.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        /></Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Card className="bubbleChart" >
          <CardHeader title="Portfolio History Animation" action={<InfoIconTooltip title="Animation" />} />
          <CardContent>
            <Typography>Below you can see your portfolios history.</Typography>
          </CardContent>
          <AnimatedPortfolioBubbleChart data={dataInArray} maturityModel={maturityModel} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Card className="bubbleChart" >
          <CardHeader title="Portfolio History" action={<InfoIconTooltip title="History" />} />
          <CardContent>
            <BusinessHistoryGraph width="100%" data={dataInArray} maturityModel={maturityModel} countDaysFromStart={showDaysFromStart} />
            <Typography>Below you each case compared to others in progress graph.</Typography>

            <Typography>Compare from start data: <Switch
              checked={showDaysFromStart}
              onChange={handleSwitchDaysFromStart}
              inputProps={{ 'aria-label': 'controlled' }}
            /></Typography>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withIdentityChecks(PortfolioHistoryView);