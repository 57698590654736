export type ScaleStatus = "active" | "removed" | "on hold" | "not tracked";

export type TimeStamped = {
  createdAt: string,
  modifiedAt: string,
}

export type ProjectFile = {
  name: string,
  url: string,
  projectId?: string,
}

export type FileUpload = {
  contentType: string,
  name: string,
  size: number,
}

export type FileUploadUrl = {
  name: string,
  url: string,
}

export type Business = TimeStamped & {
  id: string,
  businessId?: string, // history uses this instead of id
  name: string,
  businessPotential: number,
  budget: number,
  totalAddressableMarket?: number | string,
  estimatedPMFDate?: string,
  estimatedLaunchDate?: string,
  organizationUnitId?: string,
  status?: ScaleStatus,
  description?: string,
  leadPersonEmail?: string,
  startDate?: string,
  modifiedAt: string,
  horizon?: number,
  businessModel?: string,
  trelloBoardId?: string,
  trelloListId?: string,
  problemDescription?: string,
  businessBenefits?: string,
  stakeholders?: string,
  plans?: Plan[],
};

export type BusinessHistory = Business & HasScore & {
  scoreModifiedAt: string,
  organizationUnit: OrganizationUnit,
  organizationUnitName: string,
  permissionRole?: string,
  deleted?: boolean,
  periodDate: string,
  averageScoreByDay: {},
  weightedPotential: number
}

export type OrganizationUnit = TimeStamped & {
  id: string,
  name: string,
  permissionRole: string,
  organizationId: string,
  organization: {
    id: string
  }
}

export type BusinessExtended = Business & HasScore & {
  tags?: string[],
  scoreModifiedAt?: string,
  organizationUnit?: OrganizationUnit,
  organizationUnitName?: string,
  permissionRole?: string,
  deleted?: boolean,
  insights?: {
    risk?: number,
    impact?: number
  }
}

export type WithDay = {
  day: string,
}

export type HasScore = {
  score: number;
}

export type MaturityScoreOnly = HasScore & {
  maturityCategoryId: string;
  businessId: string;
}

export type ApiScoreByDay = HasScore & WithDay & {
  id: string,
  businessId: string,
  periodDate: string,
}

export type MaturityScore = MaturityScoreOnly & TimeStamped & {
  id: string,
  notes: string,
  notesScore: number, // this is the level for which the note is for
  trelloCardId?: string
}
export function isResolved(score: MaturityScore) {
  return score.score === score.notesScore;
}

export type MaturityScoreExtended = MaturityScore & {
  level?: MaturityLevel,
  category?: MaturityCategory
}

export type MaturityCategory = {
  id: string,
  order: number,
  name: string
}

export type MaturityLevel = {
  id: string,
  level: number,
  name: string
}

export type MaturityTool = {
  img: string,
  title?: string,
  "ext-url"?: string,
  description?: string,
  canvas?: string,
}

export type MaturityDescription = {
  id?: string,
  maturityCategoryId: string,
  maturityLevelId: string,
  name: string,
  description: string,
  examples: string,
  metadata?: any,
  priorityScore?: number
  tools?: MaturityTool[]
};

export type MaturityDescriptionExtended = MaturityDescription & {
  level: MaturityLevel,
  category: MaturityCategory
}


export type PriorityListItem = {
  id: number,
  levelName: string,
  levelNumber: number,
  categoryName: string,
  categoryId: string,
  objective: string,
  priorityScore: number,
  maturityModelId: string,
  friendlyId: string,
  businessId: string,
}

export type MaturityPhase = {
  id?: string,
  name: string,
  firstLevel: number,
  lastLevel: number,
  areaColor?: string,
  areaTitle?: string
}

//@TODO might make sense to move all the formatting to a jsob metadata?
export type MaturityMilestone = {
  id?: string,
  description: string | null,
  shortName: string,
  name: string,
  level: number,
  line?: "solid" | "dot" | "dash" | "longdash" | "dashdot" | "longdashdot" | null,
  lineText?: string | null,
  color?: string | null
}

// const MILESTONE_GQL = `{
//   id
//   description
//   shortName
//   name
//   level
//   line
//   lineText
//   color
// }`

export type MaturityModel = {
  id: string,
  phases: MaturityPhase[],
  milestones?: MaturityMilestone[],
  levels: MaturityLevel[],
  maxLevel: number,
  categories: MaturityCategory[],
};

export type Plan = {
  id: string,
  name?: string,
  businessId: string,
  startDate: string,
  targetDate: string
  startLevel?: number | null;
  targetLevel: number | null;
  startSelection?: string;
  targetSelection?: string;
  description?: string;
}

export const defaultMaturityPhases: MaturityPhase[] = [
  { id: "p1", name: "Idea to Concept", firstLevel: 0, lastLevel: 3 },
  { id: "p2", name: "Concept to Product", firstLevel: 3, lastLevel: 5, areaTitle: 'BOOST', areaColor: '#44000022' },
  { id: "p3", name: "Product to Market", firstLevel: 5, lastLevel: 7 },
  { id: "p4", name: "Scale", firstLevel: 7, lastLevel: 9 }
];

export const defaultMaturityMilestones: MaturityMilestone[] = [
  {
    "id": "7f7dbd86-b7bb-4d30-86b8-bb89f16ce93b",
    "shortName": "POC",
    "name": "B1: Enter",
    "description": null,
    "level": 3.5,
    "color": "#0c5bab",
    "line": "dash",
    "lineText": "POC"
  },
  {
    "id": "d684194f-d800-4d26-9110-891fab6289fa",
    "shortName": "MVP",
    "name": "B2: Exit",
    "description": null,
    "level": 5,
    "color": "#0c5bab",
    "line": "solid",
    "lineText": "MVP"
  },
  {
    "id": "eda11521-ddaf-4742-b5f4-fbd51e358200",
    "shortName": "MMP",
    "name": "General Availability launch / Minimum Marketable Product (MMP)",
    "description": null,
    "level": 6,
    "color": null,
    "line": null,
    "lineText": null
  },
  {
    "id": "0f0f99fb-9742-4d87-915f-750704f1f918",
    "shortName": "PMF",
    "name": "Product-Market Fit",
    "description": null,
    "level": 8,
    "color": null,
    "line": null,
    "lineText": null
  }
];

export const defaultMaturityModel: MaturityModel = {
  id: "defaultModel",
  phases: defaultMaturityPhases,
  milestones: defaultMaturityMilestones,
  levels: [
    { id: "l1", level: 1, name: "Exploration Formation" },
    { id: "l2", level: 2, name: "Problem Validation" },
    { id: "l3", level: 3, name: "Solution Validation" },
    { id: "l4", level: 4, name: "Business Validation" },
    { id: "l5", level: 5, name: "Productization & Preparing to Sell" },
    { id: "l6", level: 6, name: "Go To Market" },
    { id: "l7", level: 7, name: "Product-Market Fit" },
    { id: "l8", level: 8, name: "Scaling up" },
    { id: "l9", level: 9, name: "Profitable & Growing" },
  ],
  maxLevel: 9,
  categories: [
    { id: "f07e0de2-b5ef-4e6b-a759-61ba7d6a34b4", order: 1, name: "Team Composition" },
    { id: "9bae01d9-2e24-4d2b-950a-998fac80a36e", order: 2, name: "Problem & Vision" },
    { id: "a5d6f96a-65e9-4682-ac25-7db70b476910", order: 3, name: "Value Prop" },
    { id: "3f333a26-8192-47b9-95bb-fb2adafe5d31", order: 4, name: "Solution" },
    { id: "c93ad04e-3572-45a0-b85d-f68bcf7a2c35", order: 5, name: "Financial Model" },
    { id: "800ffa93-e5e6-48b4-baf3-392a8d9a159f", order: 6, name: "Market" },
    { id: "4e5e2e34-5ce4-441b-9613-9ba2b62436ab", order: 7, name: "Sustainability" },
    { id: "144864a7-49e0-4e25-99c7-670c819e4f3a", order: 8, name: "Operations" },
    { id: "f0e9148b-5090-4672-b588-131b3cc5320b", order: 9, name: "Sales" },
    { id: "375cb4f2-8a78-4f58-98c5-40cc040d6a54", order: 10, name: "Risk & Legal" },
    { id: "58b6ad56-6122-4273-8a59-be5426ead441", order: 11, name: "Value Chain" },
    { id: "6d284a87-876e-4159-ae5c-d649b5864059", order: 12, name: "Business Unit Commitment" },
  ],
};


// @TODO Fix the maturity model levels and categories mapping on the hasura level
export function constructMaturityModelFromRecord(maturityModelRecord: any) {
  const maturityModel: MaturityModel = {
    ...maturityModelRecord,
    levels: maturityModelRecord.levels.map((level: any) => level.level),
    categories: maturityModelRecord.categories.map((category: any) => category.category),
    maxLevel: (maturityModelRecord.levels_aggregate.aggregate.max.score)
  };
  return maturityModel;
}
