import React, { ComponentType, useState, } from 'react';
import { Menu, MenuProps, useSidebarState } from 'react-admin';

import AssignmentIcon from '@mui/icons-material/Assignment';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import HeartIcon from '@mui/icons-material/Favorite';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import TableRowsIcon from '@mui/icons-material/Toc';
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LinkIcon from '@mui/icons-material/Link';
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsIcon from '@mui/icons-material/Settings';
import SubMenu from './SubMenu';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useScalePermissions } from '../components/withIdentityChecks';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddchartIcon from '@mui/icons-material/Addchart';
import AppsIcon from '@mui/icons-material/Apps';
import ArchiveIcon from '@mui/icons-material/Archive';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GradeIcon from '@mui/icons-material/Grade';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import InventoryIcon from '@mui/icons-material/Inventory';
import LabelIcon from '@mui/icons-material/Label';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import ListIcon from '@mui/icons-material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleIcon from '@mui/icons-material/People';
import PieChartIcon from '@mui/icons-material/PieChart';
import PollIcon from '@mui/icons-material/Poll';
import PostAddIcon from '@mui/icons-material/PostAdd';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SchemaIcon from '@mui/icons-material/Schema';
import ScienceIcon from '@mui/icons-material/Science';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FlagIcon from '@mui/icons-material/Flag';
import VerifiedIcon from '@mui/icons-material/Verified';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkIcon from '@mui/icons-material/Work';

interface ScaleMenuState {
    [submenuName: string]: boolean;
}

const ScaleMenu: ComponentType<MenuProps> = props => {
    const [open, setOpen] = useSidebarState();
    const dense = false

    const [state, setState] = useState<ScaleMenuState>({
        menuCases: false,
        menuModel: false,
    });

    const handleToggle = (menu: string) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
        setOpen(true);
    };

    const { permissions, isLoading: loading } = useScalePermissions();

    if (loading) return <LoadingAnimation loadingText="Loading authentication" />

    const isSingleBusiness = permissions.isLimitedAccess;
    const showMenu = !permissions.isUnverified && !permissions.hasUnknownOrganization && !isSingleBusiness;

    return (
        <Menu>
            {isSingleBusiness ?
                <Menu.Item to="/" primaryText="Businesses" leftIcon={<WorkIcon />} />
                :
                <Menu.Item to="/" primaryText="Dashboard" leftIcon={<BubbleChartIcon />} />
            }
            {showMenu && [
                <Menu.Item key="businessList" to="businessList" primaryText="menu.businesses" leftIcon={<WorkIcon />} />,
/*                <SubMenu key="casesMenu"
                    handleToggle={() => handleToggle('menuCases')}
                    isOpen={state.menuCases}
                    sidebarIsOpen={open}
                    name="menu.reports"
                    icon={<AssessmentIcon />}
                    dense={dense}
                > */
                    <Menu.Item key="steeringReport" to="/steeringReport" primaryText="Steering &amp; Team" leftIcon={<ViewTimelineIcon />} />,
                    <Menu.Item key="history" to="history" primaryText="Portolio History" leftIcon={<TimelineIcon />} />,
                    <Menu.Item key="benchmarking" to="benchmarking" primaryText="Benchmarking" leftIcon={<CompareArrowsIcon />} />,
//                    <Menu.Item to="/" disabled primaryText="Coming soon:" />
//                    <Menu.Item to="/" disabled primaryText="Health &amp; Viability" leftIcon={<HeartIcon />} />
//                    <Menu.Item to="/" disabled primaryText="Pipeline Velocity" leftIcon={<DoubleArrowIcon />} />
           //     </SubMenu>,
                <Menu.Item key="earlyInsights" to="earlyInsights" primaryText="Foresight (beta)" leftIcon={<LightbulbIcon />} />,
                <SubMenu key="settingsMenu"
                    sidebarIsOpen={open}
                    isOpen={state.menuSettings}
                    handleToggle={() => handleToggle('menuSettings')}
                    name="menu.settings"
                    dense={dense}
                    icon={<SettingsIcon />}
                >
                    <Menu.Item key="scale_organizationUnit" to="scale_organizationUnit" primaryText="menu.organizationUnits" leftIcon={<AccountTreeIcon />} />
                    <Menu.Item key="integrations" to="integrations" primaryText="menu.integrations" leftIcon={<LinkIcon />} />
                </SubMenu>,
            ]}

            {permissions.isAdmin && [
                <Divider key="divider" />,
                <Menu.Item key="adminMenu" to="/" disabled leftIcon={<ArrowRightIcon />} primaryText="ADMIN MENU" />,
                <SubMenu key="modelMenu"
                    handleToggle={() => handleToggle('menuModel')}
                    isOpen={state.menuModel}
                    sidebarIsOpen={open}

                    name="menu.maturityModel"
                    icon={<ViewComfyIcon />}
                    dense={dense}
                >
                    <Menu.Item key="scale_maturityCategory" to="scale_maturityCategory" primaryText="Categories" leftIcon={<TypeSpecimenIcon />} />
                    <Menu.Item key="scale_maturityLevel" to="scale_maturityLevel" primaryText="Levels" leftIcon={<TableRowsIcon />} />
                    <Menu.Item key="scale_maturityDescription" to="scale_maturityDescription" primaryText="Descriptions" leftIcon={<DescriptionIcon />} />

                </SubMenu>,
                <Menu.Item key="scale_organization" to="scale_organization" primaryText="Customer Orgs" leftIcon={<HomeIcon />} />,
                <Menu.Item key="adminSettings" to="adminSettings" primaryText="Admin Settings" leftIcon={<SettingsIcon />} />
            ]}

        </Menu>
    );
}

export default ScaleMenu;

/*
{
    "message": "Could not verify JWT: JWTExpired",
    "extensions": {
        "path": "$",
        "code": "invalid-jwt"
    }
}
*/