import { ApolloClient, DocumentNode } from '@apollo/client'
import gql from 'graphql-tag'
import { DataProvider, UpdateParams } from 'react-admin'
import { useSelector } from 'react-redux'
import { ScaleState, store } from '../Store'
import { Business, BusinessExtended, FileUpload, FileUploadUrl, PriorityListItem, ProjectFile } from '../../model/ScaleTypes'
import { isNotNil } from '../../util/ScaleUtils'

const LIST_PROJECT_FILES = gql(`
  query ListProjectFiles($projectId: uuid!) {
    api_business(where: {id: {_eq: $projectId}}) {
      id
      files {
        name
        url
      }
    }
  }
`)

const GET_PROJECT_FILE_UPLOAD_URLS = gql(`
  query GetProjectFileUploadUrls($projectId: uuid!, $files: [FileUpload!]!) {
    api_business(where: {id: {_eq: $projectId}}) {
      id
      fileUploadUrls(files: $files) {
        name
        url
      }
    }
  }
`)

const GET_PROJECT_REPORT_TEMPLATE = gql(`
  query GetProjectReportTemplate($projectId: uuid!) {
    api_business(where: {id: {_eq: $projectId}}) {
      reportTemplate {
        name
        url
      }
    }
  }
`)

interface ProjectOpParams {
  projectId: string
}

interface ProjectOperations {
  delete: (params: ProjectOpParams) => Promise<boolean>
}

interface ScaleHasuraExtensions {
  listProjectFiles: (params: ProjectOpParams) => Promise<ProjectFile[]>
  getReportTemplate: (params: ProjectOpParams) => Promise<ProjectFile | null>
  getFileUploadUrls: (params: ProjectOpParams & {files: FileUpload[]}) => Promise<FileUploadUrl[]>
  uploadFile: (params: ProjectOpParams & {file: File}) => Promise<boolean>
  getSuggestedMaturityCategories: (params: ProjectOpParams & {amount: number}) => Promise<PriorityListItem[]>
  project: ProjectOperations
}

type StrictDataProvider = Pick<DataProvider,
  'getList' | 'getOne' | 'getMany' | 'getManyReference' |
  'update' | 'updateMany' |
  'create' |
  'delete' | 'deleteMany'>

export interface ScaleDataProvider extends StrictDataProvider, ScaleHasuraExtensions {}

export const buildScaleExtensions = (
  baseDataProvider: DataProvider,
  client: ApolloClient<unknown>
): ScaleHasuraExtensions => {
  const executeQuery = (query: DocumentNode, variables: any) => client.query({
    query,
    variables,
    fetchPolicy: 'network-only',
    pollInterval: 0,
  })

  const listProjectFiles: ScaleHasuraExtensions['listProjectFiles'] = async ({ projectId }) => {
    const { data } = await executeQuery(LIST_PROJECT_FILES, { projectId })
    if (isNotNil(data)) {
      return data.api_business[0]?.files ?? [] as ProjectFile[]
    } else {
      throw new Error('Loading list of project files failed')
    }
  }

  const getReportTemplate: ScaleHasuraExtensions['getReportTemplate'] = async ({ projectId }) => {
    const { data } = await executeQuery(GET_PROJECT_REPORT_TEMPLATE, { projectId })
    if (isNotNil(data)) {
      return data.api_business[0]?.reportTemplate as ProjectFile ?? null
    } else {
      throw new Error('Getting project report template failed')
    }
  }

  const getFileUploadUrls: ScaleHasuraExtensions['getFileUploadUrls'] = async ({ projectId, files }) => {
    const { data } = await executeQuery(GET_PROJECT_FILE_UPLOAD_URLS, { projectId, files })
    if (isNotNil(data)) {
      return data.api_business[0]?.fileUploadUrls ?? [] as FileUploadUrl[]
    } else {
      throw new Error('Getting file upload urls failed')
    }
  }

  const getSuggestedMaturityCategories: ScaleHasuraExtensions['getSuggestedMaturityCategories'] = async ({
    projectId,
    amount,
  }) => {
    const { data } = await baseDataProvider.getList<PriorityListItem>('api_priorityList', {
      filter: { businessId: projectId },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    })
    if (isNotNil(data)) {
      const sortedData = data.sort((a, b) => {
        const idDiff = a.id - b.id
        return idDiff !== 0
          ? idDiff
          : a.priorityScore - b.priorityScore
      })
      return sortedData.slice(0, amount)
    } else {
      throw new Error('Getting suggested maturity categories failed')
    }
  }

  return {
    listProjectFiles,
    getReportTemplate,
    getFileUploadUrls,
    getSuggestedMaturityCategories,
    uploadFile: async ({ projectId, file }): Promise<boolean> => {
      const uploadInfo = await getFileUploadUrls({
        projectId,
        files: [{
          contentType: file.type,
          name: file.name,
          size: file.size,
        }]
      })

      const uploadResponse = await fetch(uploadInfo[0].url , {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Content-Length': `${file.size}`,
        },
        body: await file.arrayBuffer(),
      })

      if (uploadResponse.ok) {
        return true
      } else {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`)
      }
    },
    project: {
      delete: async ({ projectId }) => {
        const params = {
          id: projectId,
          data: {
              id: projectId,
              deleted: true
          },
          previousData: {
              id: projectId,
          },
        }
        const { data } = await baseDataProvider.update<Business>('scale_business', params)
        return isNotNil(data)
      }
    }
  }
}

const dataProviderSelector = (state: ScaleState) => state.provider.dataProvider

export const useScaleDataProvider = (): ScaleDataProvider => {
  return useSelector(dataProviderSelector)
}

export const getScaleDataProvider = (): ScaleDataProvider | undefined => {
  return store.getState()?.provider?.dataProvider
}
