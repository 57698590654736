import { useGetList } from 'react-admin';
import { MaturityScoreOnly } from '../model/ScaleTypes';

export default function useBusinessHistoryScores(businessId: string | undefined | null): { historyScoreData: MaturityScoreOnly[], isHistoryScoresLoading: boolean, historyScoreError: Error | null } {
  const { data: historyScoreData, isLoading: isHistoryScoresLoading, error: historyScoreError } = useGetList(
    'api_scoreByDayAndCategory',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'day', order: 'DESC' },
      filter: { businessId }
    });
  return { historyScoreData: historyScoreData as MaturityScoreOnly[], isHistoryScoresLoading, historyScoreError };
}
