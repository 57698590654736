import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, InputAdornment } from '@mui/material';
import { TrelloAPI, TrelloBoard, TrelloIcon, TrelloList } from './trelloApi';
import { SelectInput, SelectInputProps, required, useRecordContext } from 'react-admin';
import TrelloApiContext from './trelloContext';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';

export interface TrelloBoardListSelectorProps {
  api: TrelloAPI;
  onSelected?: (board: TrelloBoard | null, list: TrelloList | null) => void;
  boardId?: string;
  listId?: string;
}

interface State {
  boards: TrelloBoard[];
  lists: TrelloList[];
  selectedBoard: string;
  selectedList: string;
}

const useStyles = makeStyles()((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

export interface TrelloBoardConfigProps {
  api: TrelloAPI;
  onSelected?: (board: TrelloBoard | null, list: TrelloList | null) => void;
}

export const TrelloBoardListSelector: React.FC<TrelloBoardListSelectorProps> = ({
  api,
  boardId,
  listId,
  onSelected = () => { } }) => {
  const [state, setState] = useState<State>({
    boards: [],
    lists: [],
    selectedBoard: boardId || '',
    selectedList: listId || '',
  });

  const { classes } = useStyles();

  useEffect(() => {
    if (!api) return;
    api.getUserBoards().then((boards) => {
      setState((prev) => ({ ...prev, boards }));
    });
  }, [api]);

  useEffect(() => {
    if (state.selectedBoard) {
      if (!api) return;
      api.getBoardLists(state.selectedBoard).then((lists) => {
        setState((prev) => ({ ...prev, lists }));
      });
    }
  }, [api, state.selectedBoard]);

  useEffect(() => {
    const selectedBoard = state.boards.find((board) => board.id === state.selectedBoard) || null;
    const selectedList = state.lists.find((list) => list.id === state.selectedList) || null;
    onSelected(selectedBoard, selectedList);
  }, [state.selectedBoard, state.selectedList, state.boards, state.lists, onSelected]);



  if (!api) return <div>No Trello API available!</div>
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel>Board</InputLabel>
        <Select autoWidth displayEmpty disabled={!!boardId}
          value={state.selectedBoard}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              selectedBoard: e.target.value as string,
            }))
          }
          startAdornment={
            <InputAdornment position="start">
              <TrelloIcon size={20} />
            </InputAdornment>
          }
        >
          {state.boards.map((board) => (
            <MenuItem key={board.id} value={board.id}>
              {board.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} disabled={!state.selectedBoard}>
        <InputLabel>List</InputLabel>
        <Select
          value={state.selectedList}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              selectedList: e.target.value as string,
            }))
          }
        >
          {state.lists
            .filter((list) => !list.closed)
            .map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TrelloBoardListSelector;

interface TrelloBoardSelectInputProps extends Omit<SelectInputProps, 'choices'> {
  handleChange: (value: string) => void;
  source: string;
}

export const TrelloBoardSelectInput: React.FC<TrelloBoardSelectInputProps> = ({
  handleChange,
  ...rest
}) => {
  const record = useRecordContext();

  const { data: boards, isLoading } = useGetTrelloBoards()
  const choices = useMemo(
    () => {
      const choicesList = boards.map((board: TrelloBoard) => ({
        id: board.id,
        name: board.name,
      }));
      return choicesList;
    },
    [boards]
  );

  return (
    <SelectInput
      {...rest}
      isLoading={isLoading}
      onChange={(e) => handleChange(e.target.value)}
      defaultValue={record && rest.source ? record[rest.source] : NO_LIST_SELECTED_VALUE}
      choices={choices}
    />
  );
};

interface TrelloListSelectInputProps extends Omit<SelectInputProps, 'choices'> {
  source: string;
  boardSource: string;
  boardId: string;
}

export const NO_LIST_SELECTED_VALUE = '';

type GetTrelloBoardsReturnType = {
  data: TrelloBoard[];
  isLoading: boolean;
};

export function useGetTrelloBoards(): GetTrelloBoardsReturnType {
  const [boards, setBoards] = useState<TrelloBoard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const trelloApi = useContext(TrelloApiContext)!;

  useEffect(() => {
    trelloApi.getUserBoards()
      .then(fetchedBoards => {
        setBoards(fetchedBoards);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [trelloApi]);

  return { data: boards, isLoading };
}


type GetTrelloListsReturnType = {
  data: TrelloList[];
  isLoading: boolean;
};

export function useGetTrelloLists(boardId: string): GetTrelloListsReturnType {
  const [lists, setLists] = useState<TrelloList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const trelloApi = useContext(TrelloApiContext)!;

  useEffect(() => {
    console.log("Getting lists for ", boardId)
    if (boardId) {
      trelloApi.getBoardLists(boardId)
        .then(fetchedLists => {
          setLists(fetchedLists);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setLists([]);
      setIsLoading(false);
    }
  }, [trelloApi, boardId]);

  return { data: lists, isLoading };
}

export const TrelloListSelectInput: React.FC<TrelloListSelectInputProps> = ({
  source,
  boardSource,
  ...rest
}) => {
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();

  const boardId = getValues(boardSource);
  const { data: lists, isLoading } = useGetTrelloLists(boardId);

  const choices = useMemo(
    () => {
      const choicesList = lists.map((list: TrelloList) => ({
        id: list.id,
        name: list.name,
      }))
      return choicesList;
    },
    [lists]
  );

  useEffect(() => {
    if (isLoading) return;
    const currentList = getValues(source);
    if (lists.find(list => list.id === currentList)) return;
    setValue(source, null); // set the current list selection empty
  }, [isLoading, lists, boardId, setValue, getValues, source])

  const validate = [];
  if (!boardId) validate.push(required());
  return (
    <SelectInput validate={validate}
      {...rest}
      source={source}
      isLoading={isLoading}
      defaultValue={record && source ? record[source] : NO_LIST_SELECTED_VALUE}
      choices={choices}
    />
  );
};