import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import { LevelUpBenchmarkChart } from '../components/charts/LevelUpBenchmarkChart';
import { Title } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { Grid } from '@mui/material';
import { withIdentityChecks } from '../components/withIdentityChecks';

const BenchmarkingView = (props: any) => {
  document.title = "Scale SaaS - Benchmarking"
  return (
    <div>
      <Title title="Benchmarking" />
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="bubbleChart" >
            <CardHeader title="Benchmarking" action={<InfoIconTooltip title="Benchmarking" />} />
            <CardContent>
              <Typography>How does your innovation pipeline perform? This benchmark data shows fastest, slowest and average times how long does it take for a team to pass certain level.</Typography>
            </CardContent>
            <LevelUpBenchmarkChart />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withIdentityChecks(BenchmarkingView);