import { PDFDocument, PDFImage } from "pdf-lib";
import { getDocument } from "pdfjs-dist";
import { ProjectFile } from "../../model/ScaleTypes";

export interface FieldPosition {
  name: string;
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

const guessImageFormat = (filePath: string): 'png' | 'jpg' | 'unknown' => {
  if (filePath.endsWith('.png')) {
    return 'png'
  }

  if (filePath.endsWith('.jpg') || filePath.endsWith('.jpeg')) {
    return 'jpg'
  }

  return 'unknown'
}

export const getEmbeddedImage = async (pdfDoc: PDFDocument, imageUrl: string): Promise<PDFImage> => {
  const filePath = new URL(imageUrl).pathname
  const format = guessImageFormat(filePath)

  if (format === 'unknown') {
    return Promise.reject(`File ${filePath} is not a supported image type`)
  }

  const response = await fetch(imageUrl)
  const imageBytes = await response.arrayBuffer()

  switch (format) {
    case 'png':
      return pdfDoc.embedPng(imageBytes)
    case 'jpg':
      return pdfDoc.embedJpg(imageBytes)
  }
}

/**
 * Gets all the form field positions from a PDF document.
 * @param pdfUrl url / path to the PDF document
 * @returns FieldPosition[] list of field positions
 */
export const getFieldPositions = async (pdfUrl: string | ArrayBuffer): Promise<FieldPosition[]> => {
  const loadingTask = getDocument(pdfUrl);
  const pdf = await loadingTask.promise;

  const positions: FieldPosition[] = [];

  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const annotations = await page.getAnnotations();

    annotations.forEach(annotation => {
      if ('fieldName' in annotation && annotation.fieldName) {
        positions.push({
          name: annotation.fieldName,
          page: i,
          x: annotation.rect[0],
          y: annotation.rect[1],
          width: annotation.rect[2] - annotation.rect[0],
          height: annotation.rect[3] - annotation.rect[1],
        });
      }
    });
  }

  return positions;
};