import { all } from "redux-saga/effects";
import { providerSagas } from "./provider/ProviderSagas";

// const saga = function* rootSaga() {
//   yield all(
//     [
//           adminSaga(dataProvider, authProvider),
//           // add your own sagas here
//       .map(fork)
//   );
// };

export function* sagas() {
  yield all([
    providerSagas()
  ])
}