import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    Button,
    EmailField,
    FunctionField,
    ReferenceField,
    ResourceContextProvider,
    RichTextField,
    SimpleShowLayout,
    TextField,
    useGetList,
    useNotify,
    useRedirect,
    useShowController,
    useTranslate,
} from 'react-admin';
import { useParams } from "react-router-dom";
import {
    Business,
    BusinessExtended,
    Plan,
} from "../../model/ScaleTypes";
import { AutomaticPriorityList } from "../../components/AutomaticPriorityList";
import { createMaturityChartData } from "../../components/charts/MaturityChart";
import { MaturityScoreCard } from "../../components/MaturityScore";
import { BusinessInfoCard } from "../../components/cards/BusinessInfoCard";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { createBusinessHistoryGraphData } from "../../components/charts/BusinessHistoryGraph";
import useMaturityModel from "../../state/useMaturityModel";
import InfoIconTooltip from "../../components/InfoIconTooltip";
import PlanCard from "../../components/cards/PlanCard";
import { canCreateReport } from "../../pages/CustomReportPage";
import useBusinessHistoryScores from "../../state/useBusinessHistoryScores";
import { PlotlyChart } from "../../components/charts/PlotlyCharts";
import DeleteProjectButton from "../../components/DeleteButtonWithConfirmation";
import { useScalePermissions } from "../../components/withIdentityChecks";
import { useBusinessHistoryWithScore, useMaturityScoreData } from "../../state/useMaturityScoreData";
import { useScaleDataProvider } from "../../state/provider/ScaleDataProvider";
import { isNotNil } from "../../util/ScaleUtils";
import { SummaryCoach } from "../../pages/OpenAI";
import Timeline from "../../components/Timeline";
import ProjectFilesCard from "./ProjectFilesCard";

export const BusinessWithInfoShow = () => {
    const [chartType, setChartType] = useState<('bar' | 'radar')>('bar');
    const [filterNonEvaluated, setFilterNonEvaluated] = useState<boolean>(true);
    const [isReportPossible, setIsReportPossible] = useState<boolean | undefined>(undefined);

    const { id } = useParams();
    const redirect = useRedirect();
    const translate = useTranslate();
    const dataProvider = useScaleDataProvider();
    const notify = useNotify();
    document.title = "Scale SaaS - Business Info"

    // if error handling want to be catched, pass onError on queryParameters
    const {
        record,
        isLoading: isRecordLoading,
        error: recordError,
        resource
    } = useShowController();

    const project = record as BusinessExtended
    const { permissions } = useScalePermissions()

    // This checks if the report creation is possible for the business
    useEffect(() => {
        if (isReportPossible === undefined && !isRecordLoading && isNotNil(project)) {
            canCreateReport(project).then(setIsReportPossible)
        }
    }, [isRecordLoading, project]);

    const { maturityModel, loading: isMaturityModelLoading } = useMaturityModel();
    const { historyScoreData, isHistoryScoresLoading, historyScoreError } = useBusinessHistoryScores(id);
    const { data: maturityScoreData, isLoading: isLoadingScoreData } = useMaturityScoreData(id);
    const { data: businessHistoryData, isLoading: isBusinessHistoryLoading, error: businessHistoryError } = useBusinessHistoryWithScore(id);

    const { data: automaticPriorityList, isLoading: isAutomaticPriorityListLoading, error: priorityError } = useGetList(
        'api_priorityList', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: { businessId: id }
    }
    );

    const loading =
        isLoadingScoreData ||
        isRecordLoading ||
        isBusinessHistoryLoading ||
        isMaturityModelLoading ||
        isAutomaticPriorityListLoading ||
        isHistoryScoresLoading;

    if (loading) return <LoadingAnimation loadingText={translate('generic.loading', { content: 'business' })} />
    if (recordError || businessHistoryError || historyScoreError || priorityError) {
        console.log(recordError);
        console.log(businessHistoryError);
        console.log(historyScoreError);
        console.log(priorityError);
        return <Alert severity="error">{translate('errors.loading')}</Alert>
    }

    if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>
    if (!project) return <Alert severity="error">{translate('errors.missing', { itemType: 'project' })}</Alert>

    const redirectToMaturity = () => { redirect(`/businessMaturity/${id}`); }
    const redirectToEdit = () => { redirect(`/scale_business/${id}`); }
    const redirectToReport = () => { redirect(`/businessReport/${id}`); }

    const plans: Plan[] = project.plans || [];
    const maturityChartParams = createMaturityChartData({ maturityModel, scores: historyScoreData, plans, type: chartType })
    const businessHistoryGraphParams = createBusinessHistoryGraphData({
        maturityModel,
        data: businessHistoryData,
        countDaysFromStart: false,
        plans,
        // estimatedLaunchDate is missing as it needs to be fetched from the record.
    })

    const handleDelete = () => {
        if (!project) {
            notify('errors.missing', { type: 'error', messageArgs: { itemType: 'Project' } });
            return;
        }

        dataProvider.update(
            'scale_business',
            {
                id: project.id,
                data: {
                    id: project.id,
                    deleted: true
                },
                previousData: {
                    id: project.id
                },
            }
        )
            .then(() => {
                notify('generic.deleted', { type: "success", messageArgs: { itemType: "Project" } });
                redirect('/businessList');
            })
            .catch((error) => {
                notify(`Error: ${error.message}`);
            });
    };

    //console.log(maturityScoreData);
    //const grouped = groupByReduceFunction(maturityScoreData, (item) => dayjs(item.modifiedAt).format("YYYY-MM-DD"));
    //    console.log(Object.keys(grouped).length);
    //    console.log(businessHistoryData);

    const events = [
        { date: new Date(2023, 0, 1), label: 'New Year\'s Day' },
        { date: new Date(2023, 1, 14), label: 'Valentine\'s Day' },
        { date: new Date(2023, 6, 4), label: 'Independence Day' },
        { date: new Date(2023, 11, 25), label: 'Christmas Day' },
    ];
    return (
        <ResourceContextProvider value={resource}>
            <Grid container style={{ display: 'flex' }} alignItems="stretch">
                <Grid item xs={12} sm={12} md={6}>
                    <Grid item>
                        <Card>
                            <CardHeader title={translate('pos.businessShow.projectInformation')}
                                action={<DeleteProjectButton projectName={project?.name || ''} onConfirmDelete={handleDelete} />} />
                            <CardContent>
                                <SimpleShowLayout record={project}>
                                    <TextField source="name" />
                                    <TextField label="business.description" source="description" />
                                    <FunctionField source="horizon" render={
                                        function (record: Business) {
                                            if (!record.horizon) return "";
                                            return `Horizon ${record.horizon}: ` + translate(`pos.definitions.h${record.horizon}definition`)
                                        }
                                    }
                                    />
                                    <EmailField source="leadPersonEmail" />
                                    <TextField source="status" />
                                    <ReferenceField source="organizationUnitId" reference="scale_organizationUnit">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    {project.problemDescription && <RichTextField label="business.problemDescription" source="problemDescription" />}
                                    {project.businessBenefits && <TextField label="business.businessBenefits" source="businessBenefits" />}
                                    {project.stakeholders && <TextField label="business.stakeholders" source="stakeholders" />}
                                    <FunctionField label="business.tags" render={
                                        function (record: BusinessExtended) {
                                            return record.tags?.map((tag, idx) => <Chip size="small" key={idx} label={tag} />)
                                        }
                                    }
                                    />
                                </SimpleShowLayout>
                            </CardContent>
                            <CardActions>
                                <Button variant="outlined" color="primary" label={translate('actions.edit_information')} onClick={() => redirectToEdit()} />
                                <Button variant="contained" style={{ marginLeft: 10 }} color="primary" label={translate('actions.open_assessment')} onClick={redirectToMaturity} />
                                <Button variant="outlined" disabled={!isReportPossible} color="primary" label={translate('actions.open_report' + (isReportPossible ? '' : '_template_missing'))} onClick={() => redirectToReport()} />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item>
                        <PlanCard business={project} plans={plans} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Grid item>
                        <BusinessInfoCard record={project} />
                    </Grid>
                    <Grid item>
                        <MaturityScoreCard data={businessHistoryData} maturityModel={maturityModel} onClick={redirectToMaturity} />
                    </Grid>
                    <Grid item>
                        <SummaryCoach business={project} scoresWithNotes={maturityScoreData} />
                    </Grid>
                    <Grid item>
                        <ProjectFilesCard project={project} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader title={translate('pos.businessShow.currentMaturity')} action={<InfoIconTooltip title={translate('pos.definitions.currentMaturity')} />} />
                        <CardContent>
                            <PlotlyChart params={maturityChartParams} />
                            <Button variant="outlined" label={chartType === 'bar' ? 'Show radar chart' : 'Show bar chart'} onClick={() => setChartType(chartType === 'bar' ? 'radar' : 'bar')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader title={translate('pos.businessShow.maturityHistory')} action={<InfoIconTooltip title={translate('pos.definitions.maturityHistory')} />} />
                        <CardContent>
                            <PlotlyChart params={businessHistoryGraphParams} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Recommended Priorities (automated)" action={<InfoIconTooltip title={translate('pos.definitions.automaticPriorityList')} />} />
                        <AutomaticPriorityList filterNonEvaluated={filterNonEvaluated} scores={maturityScoreData} maxResults={5} automaticPriorityList={automaticPriorityList || []} />
                        <Button variant="text" label={filterNonEvaluated ? 'Show all' : 'Show only evaluated'} onClick={() => setFilterNonEvaluated(!filterNonEvaluated)} />
                    </Card>
                </Grid>
            </Grid>
        </ResourceContextProvider >
    );
}
