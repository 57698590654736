import { forwardRef, useContext, useEffect, useState } from 'react';
import { AppBar, UserMenu, useTranslate, usePermissions, TitlePortal } from 'react-admin';
import PolicyIcon from '@mui/icons-material/Policy';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { makeStyles } from 'tss-react/mui';
import { useAuth0 } from "@auth0/auth0-react";
import MenuItem from '@mui/material/MenuItem';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { SettingsContext } from '../state/SettingsContext';
import { useScalePermissions } from '../components/withIdentityChecks';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { marked } from 'marked';
import NotificationsIcon from '@mui/icons-material/Notifications';
import changelogFile from '../CHANGELOG.md';


const useStyles = makeStyles()((theme: any) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    barColor: {
        backgroundColor: 'green'
    },
    spacer: {
        flex: 1,
    },
    logo: {
        maxWidth: "150px",
        background: 'white',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        border: '5px solid white',
        borderRadius: '10px'
    },
    linkStyle: {
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

interface CustomMenuItemProps {
    menuTextKey: string;
    icon: JSX.Element;
    to?: string;
    onClick?: () => void;
}

const CustomMenuItem = forwardRef<any, CustomMenuItemProps>((props, ref) => {
    const translate = useTranslate();
    const menuTextKey = props.menuTextKey;
    const icon = props.icon;
    const link = props.to || "/";
    return (
        <MenuItem ref={ref} component={Link} to={link} onClick={props.onClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{translate(menuTextKey)}</ListItemText>
        </MenuItem>
    );
});

const CustomUserMenu = (props: any) => {
    const { user, logout } = useAuth0();
    const { permissions, isLoading } = useScalePermissions();
    if (isLoading) return <LoadingAnimation loadingText="Loading permissions" />

    return <UserMenu label={user?.name}>
        <MenuItem disabled><ListItemText>Role: {permissions.roles}{permissions.isLimitedAccess ? ' (limited)' : ''}</ListItemText></MenuItem>
        <CustomMenuItem icon={<PersonAddAlt1Icon />} to="/invitecolleague" menuTextKey="pos.usermenu.inviteUser" />
        <CustomMenuItem icon={<AllInclusiveIcon />} to="/managesubscription" menuTextKey="pos.usermenu.subscription" />
        <CustomMenuItem icon={<PolicyIcon />} to="/termsandconditions" menuTextKey="pos.usermenu.terms" />
        <CustomMenuItem icon={<LogoutIcon />} menuTextKey="pos.usermenu.logout" onClick={() => { logout() }} />
    </UserMenu>
};

const ChangelogDialog = () => {
    const [open, setOpen] = useState(false);
    const [changelog, setChangelog] = useState('');
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    useEffect(() => {
        const fetchAndParseMarkdown = async () => {
            try {
              const response = await fetch(process.env.PUBLIC_URL + '/CHANGELOG.md');
              const text = await response.text();
              const html = await marked.parse(text);
              setChangelog(html);
            } catch (error) {
              console.error('Error fetching or parsing markdown:', error);
              // Handle the error according to your app's requirements
            }
          };
      
          fetchAndParseMarkdown();
    }, []);
  
    return (
      <div>
        <IconButton color="inherit" onClick={handleClickOpen}>
          <NotificationsIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose} aria-labelledby="changelog-dialog-title" fullWidth maxWidth="md">
          <DialogContent dividers>
            <div dangerouslySetInnerHTML={{ __html: changelog }} />
          </DialogContent>
        </Dialog>
      </div>
    );
  };


const CustomAppBar = (props: any) => {
    const { classes } = useStyles();
    const settings = useContext(SettingsContext);

    const appBarStyle = {
        backgroundColor: settings.interfaceSettings.demoMode ? "green" : "",
    };

    return (
        <AppBar {...props} style={appBarStyle} elevation={2} userMenu={<CustomUserMenu />}>
            <TitlePortal />
            {settings.interfaceSettings.demoMode && <Button variant="contained" onClick={() => settings.setInterfaceSetting('demoMode', false)}>DEMO</Button>}
            <ChangelogDialog />
            <img src={'/scale-company-logo.png'} alt="Scale Company logo" className={classes.logo} />
        </AppBar>
    );
};

export default CustomAppBar;