import { Step } from "react-joyride";



export const maturityTableTutorialSteps: Step[] = [
  {
    content: (
      <div>
        <p>This is an introduction to Scale Maturity Assessments.</p>
      </div>
    ),
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'center',
    floaterProps: {
      disableAnimation: true,
    },
    target: '.maturityTableCont',
    title: 'Tutorial'
  },
  {
    content: (
      <div>
        <p>It’s a level-up game and as you move up with your idea...</p>
      </div>
    ),
    placement: 'right',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.levelName_5',
    title: 'Levels',
    disableOverlay: true
  },
  {
    content: (
      <div>
        <p>...you need to hit milestones in each of the categories.</p>
      </div>
    ),
    placement: 'left',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.categories',
    title: 'Categories',
    disableOverlay: true
  },
  {
    content: (
      <div>
        <p>Let's take "Financial Model" category as an example.</p>
      </div>
    ),
    placement: 'left',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.fin_cat',
    title: 'Objectives',
    disableOverlay: true
  },
  {
    content: (
      <div>
        <p>We always start with the basics. Here you can see the level 1 objective for the category.</p>
        <p>Click on the objective and hover your mouse over the orange symbol to see more detailed description and examples.</p>
      </div>
    ),
    placement: 'left',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.fin_1',
    title: 'Level 1: Exploration Formation',
    hideFooter: true,
  },
  {
    content: (
      <div>
        <p>The objectives get more difficult along the way.</p>
        <p>Here you would need to start thinking about the initial business model based on your customer interviews and other insights.</p>
      </div>
    ),
    placement: 'left',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.fin_2',
    title: 'Level 2: Problem Validation',
    disableOverlay: true
  },
  {
    content: (
      <div>
        <p>Maybe you are not this far yet? But you get the idea!</p>

        <p>By comparing the level criteria above and below you will soon figure out on which level you are currently at and what is required in order to move forward.</p>
      </div>
    ),
    placement: 'left',
    target: '.fin_3',
    title: 'Level 3: Solution Validation',
    disableOverlay: true
  },
  {
    content: (
      <div>
        You can always return to the team view from here!
      </div>
    ),
    placement: 'right',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.controls',
    title: 'Controls',
    disableOverlay: true
  },
  {
    content: (
      <div>
        <p>Start your first assessment from <strong>"Team Composition"</strong> and go through the categories from left to right!</p>

        <p>Remember you can always skip categories which you feel are not relevant for your project. These categories are not calculated into your scores.</p>
      </div>
    ),
    placement: 'right',
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    disableOverlay: true,
    target: '.team_1',
    title: 'Tutorial completed! 🎉',
    locale: { last: <strong aria-label="skip">OK</strong> },
    hideBackButton: true
  },
];