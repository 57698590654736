import React, { ReactElement, useContext, useState } from "react";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import TrelloApiContext from "./trelloContext";

export interface Integration {
  icon: ReactElement
  logo: ReactElement;
  name: string;
  isConfigured: boolean;
  createOAuthUrl: () => string;
  disconnectUrl: () => string;
  isWorking: () => Promise<boolean>;
}

const TrelloConfigurationScreen: React.FC = () => {
  const [working, setWorking] = useState<boolean>(false);

  const trello = useContext(TrelloApiContext)!;
  trello.isWorking().then(value => {
    setWorking(value);
  });

  const integrations = [trello];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {integrations.map((integration) => (
            <TableRow key={integration.name}>
              <TableCell>{integration.logo}</TableCell>
              <TableCell>{working ? "Working" : "Not Configured"}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color={integration.isConfigured ? "secondary" : "primary"}
                  onClick={() => window.open(working ? integration.disconnectUrl() : integration.createOAuthUrl())}
                >
                  {working ? "Disconnect" : "Configure"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrelloConfigurationScreen;
