import { Button } from "react-admin";
import FullscreenIcon from '@mui/icons-material/FullscreenRounded';
import { useSettings } from "../state/SettingsContext";

export const FullScreenButton = () => {
  const settings = useSettings();
  const handleFullscreenChange = function () {
    const isFullscreen = document.fullscreenElement !== null;
    settings.setInterfaceSetting('fullScreen', isFullscreen);
  };
  document.addEventListener('fullscreenchange', handleFullscreenChange);

  const toggleFullScreen = function () {
    const isFullScreen = document.fullscreenElement !== null;
    if (isFullScreen) document.exitFullscreen();
    else document.body.requestFullscreen();
  };

  return <Button variant='text' className="button-start" onClick={() => toggleFullScreen()}><FullscreenIcon /></Button>
}