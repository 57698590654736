import { BusinessExtended, BusinessHistory, constructMaturityModelFromRecord, MaturityModel, MaturityScore } from '../model/ScaleTypes'
import { isNil } from '../util/ScaleUtils'
import { useEffect, useState } from 'react'
import { ScaleDataProvider, useScaleDataProvider } from './provider/ScaleDataProvider'

type ProjectDashboardData = {
  project?: BusinessExtended | null
  maturityHistory: BusinessHistory[]
  maturityModel?: MaturityModel | null
  maturityScores: MaturityScore[]
}

type Loader<T> = (dataProvider: ScaleDataProvider, projectId?: string) => Promise<T>
type LoadProject = Loader<BusinessExtended | null>
type LoadMaturityHistory = Loader<BusinessHistory[]>
type LoadMaturityModel = Loader<MaturityModel | null>
type LoadMaturityScores = Loader<MaturityScore[]>

const loadProject: LoadProject = async (dataProvider, projectId) => {
  if (isNil(projectId)) return null

  const response = await dataProvider.getOne<BusinessExtended>('api_business', { id: projectId })
  return response.data
}

const loadMaturityHistory: LoadMaturityHistory = async (dataProvider, projectId) => {
  if (isNil(projectId)) return []

  const response = await dataProvider.getList<BusinessHistory>(
    'api_businessHistoryWithScore',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'modifiedAt', order: 'ASC' },
      filter: { businessId: projectId }
    }
  )
  return response.data ?? []
}

const loadMaturityModel: LoadMaturityModel = async (dataProvider, projectId) => {
  if (isNil(projectId)) return null

  const response = await dataProvider.getList<MaturityModel>(
    'api_maturityModelByOrganization',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { },
    },
    // { staleTime: 2 * 60 * 1000 }
  )

  const maturityModel = response?.data[0]
  return maturityModel
    ? constructMaturityModelFromRecord(maturityModel)
    : null
}

const loadMaturityScores: LoadMaturityScores = async (dataProvider, projectId) => {
  if (isNil(projectId)) return []

  const response = await dataProvider.getList<MaturityScore>(
    'scale_maturityScores',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { businessId: projectId },
    }
  )
  return response.data ?? []
}

export const useProjectDashboardData = (projectId?: string): ProjectDashboardData => {
  const dataProvider = useScaleDataProvider()
  const [data, setData] = useState<ProjectDashboardData>({
    project: undefined,
    maturityHistory: [],
    maturityModel: undefined,
    maturityScores: [],
  })

  useEffect(() => {
    if (dataProvider) {
      Promise.all([
        loadProject(dataProvider, projectId),
        loadMaturityHistory(dataProvider, projectId),
        loadMaturityModel(dataProvider, projectId),
        loadMaturityScores(dataProvider, projectId),
      ]).then(([project, maturityHistory, maturityModel, maturityScores]) => {
        setData({
          project,
          maturityHistory,
          maturityModel,
          maturityScores,
        })
      })
    }
  }, [projectId, dataProvider])

  return data
}
