import { Chip, SxProps, Theme } from "@mui/material";
import { MaturityCategory } from "../model/ScaleTypes";
import { TAG_STYLE } from "../pages/ProjectDashboard";
import { getTextColorForBackground, MATURITY_CATEGORY_COLORS } from "./Colors";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useState } from "react";


type CategoryChipProperties = {
  category: MaturityCategory;
  isSelectable?: boolean;
  isSelected?: boolean;
  isMinimized?: boolean;
  sx?: SxProps<Theme> | undefined;
  onClick?: (category: MaturityCategory) => void;
  onDelete?: (category: MaturityCategory) => void;
}

export const CategoryChip: React.FC<CategoryChipProperties> = ({
  category,
  sx = {},
  isSelectable = false,
  isSelected = !isSelectable,
  onClick = () => { },
  onDelete = () => { }
}) => {
  const categoryBgColor = MATURITY_CATEGORY_COLORS[category.order - 1]
  const categoryTextColor = getTextColorForBackground(categoryBgColor)
  const colors = {
    default: {
      backgroundColor: isSelected ? categoryBgColor : 'default',
      color: isSelected ? categoryTextColor : 'default',
    },
    hover: {
      backgroundColor: categoryBgColor,
      color: categoryTextColor,
    }
  }

  return (
    <Chip
      size='small'
      sx={{
        padding: '5px 6px 5px 6px',
        margin: '2px',
        ...TAG_STYLE,
        ...colors.default,
        ...sx,
        [':hover']: {
          ...colors.hover,
        },
        ['.MuiChip-deleteIcon']: {
          color: colors.default.color,
          paddingLeft: '5px',
          margin: 0,
        },
        ['&:hover .MuiChip-deleteIcon']: {
          color: colors.hover.color,
        },
        ['& .MuiChip-label']: {
          padding: 0,
        },
      }}
      key={category.id}
      label={category.name}
      deleteIcon={isSelectable ? isSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : undefined}
      onClick={isSelectable ? () => onClick(category) : undefined}
      onDelete={isSelectable ? () => onDelete(category) : undefined}
    />
  )
}

export const CategoryChipList: React.FC<{ categories: MaturityCategory[] }> = ({ categories }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      //      onMouseEnter={() => setIsHovered(true)}
      //      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}
    >
      {categories
        .sort((a, b) => a.order - b.order)
        .map((cat, index) => (
          <div
            key={cat.id}
            style={{
              position: isHovered ? 'absolute' : 'static',
              top: isHovered ? `${index * 20}px` : 'auto',
              left: isHovered ? '0' : 'auto',
              padding: '0px',
              margin: '0px',
              width: 'auto',
              background: 'white',
              zIndex: isHovered ? 1000 + index : 1, // High enough to be above other content
              transition: 'all 0.3s',
              pointerEvents: isHovered ? 'auto' : 'none', // Prevent interference with other elements when not hovered
            }}
          >
            {isHovered ? (
              <CategoryChip category={cat} />
            ) : (
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ color: MATURITY_CATEGORY_COLORS[cat.order - 1], margin: '0px' }}
              />
            )}
          </div>
        ))}
    </div>
  );
};

