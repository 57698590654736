import { SvgIconProps } from "@mui/material";
import axios from "axios";
import { Integration } from "./TrelloConfigScreen";
import trelloConfig from "./trelloConfig";
const TRELLO_TOKEN_KEY = 'trello_token';

const trelloApi = axios.create({
  baseURL: 'https://api.trello.com/1/',
});

trelloApi.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params.key = trelloConfig.apiKey;
  config.params.token = localStorage.getItem(TRELLO_TOKEN_KEY);
  return config;
});

export interface TrelloBoard {
  id: string,
  shortUrl: string,
  name: string,
}

export interface TrelloList {
  id: string,
  idBoard: string,
  name: string,
  closed: boolean,
}

export interface TrelloCard {
  id: string,
  name: string,
  desc: string,
  // pos: string | number,
  // due: string, // date
  // start: string, // date
  // dueComplete: boolean,
  // closed: boolean,
  // idBoard: string,
  // idList: string,
  // idMembers: string[],
  // idLabels: string[],
  // url: string,
  // cover: {
  //   color: string,
  //   brightness: "dark" | "light",
  //   url: string,
  //   idAttachment: string,
  //   size: "normal" | "full"
  // },
  // dueReminder: unknown,
  // email: unknown,
  // labels: unknown[],
  // shortLink: string,
  shortUrl: string,
  // subscribed: boolean,
}

interface IconProps {
  size?: number,
}

export const TrelloLogo: React.FC<SvgIconProps & IconProps> = ({ size = 20, ...props }) => {
  return (<svg {...props}
    height={size} viewBox="0.5720224450860822 0.06999628194666574 311.1279775549139 63.44000371805333" xmlns="http://www.w3.org/2000/svg"><linearGradient id="a" x1="50.048%" x2="50.048%" y1="100%" y2="0%"><stop offset="0" stopColor="#0052cc" /><stop offset="1" stopColor="#2684ff" /></linearGradient><g fill="none" fillRule="evenodd"><path d="M55.59.07H8A7.42 7.42 0 0 0 .58 7.48V55A7.42 7.42 0 0 0 8 62.45h47.59A7.42 7.42 0 0 0 63 55V7.48A7.42 7.42 0 0 0 55.59.07zM27.5 45a2.48 2.48 0 0 1-2.5 2.47H14.6A2.47 2.47 0 0 1 12.14 45V14.05a2.47 2.47 0 0 1 2.46-2.47H25a2.48 2.48 0 0 1 2.47 2.47zm24-14.21a2.47 2.47 0 0 1-2.5 2.47H38.6a2.48 2.48 0 0 1-2.47-2.47V14.05a2.48 2.48 0 0 1 2.47-2.47H49a2.47 2.47 0 0 1 2.46 2.47z" fill="url(#a)" /><g fill="#293856" fillRule="nonzero"><path d="M129.92 4.64V16.7h-14.29v45.75h-13.78V16.7H87.56V4.64zM147.46 62.45h-12.74v-45h12.74v8.62c2.42-6.07 6.29-9.68 13.18-9.24v13.33c-9-.7-13.18 1.5-13.18 8.71zM230.24 62.8c-8.35 0-13.6-4-13.6-13.46V.07h12.83v47.51c0 2.73 1.8 3.7 4 3.7a14.08 14.08 0 0 0 1.9-.09v11.09a18.75 18.75 0 0 1-5.13.52zM257 62.8c-8.35 0-13.61-4-13.61-13.46V.07h12.83v47.51c0 2.73 1.81 3.7 4.05 3.7a13.86 13.86 0 0 0 1.89-.09v11.09a18.66 18.66 0 0 1-5.16.52zM268.31 39.93c0-13.9 8-23.41 21.78-23.41S311.7 26 311.7 39.93s-7.92 23.58-21.61 23.58-21.78-9.77-21.78-23.58zm12.49 0c0 6.77 2.84 12.14 9.29 12.14s9.13-5.37 9.13-12.14-2.75-12-9.13-12-9.29 5.22-9.29 12zM177.84 44c3.567.392 7.152.602 10.74.63 9.76 0 18-2.62 18-12.07 0-9.17-8.47-16.06-18.66-16.06-13.72 0-22.51 9.95-22.51 23.85 0 14.43 7.58 23 24.71 23A41.08 41.08 0 0 0 205 60.69V49.91c-4.4 1.41-9.35 2.81-14.43 2.81-6.82 0-11.57-2.24-12.73-8.72zm9.82-17.68c3.61 0 6.51 2.45 6.51 5.8 0 4.31-4.55 5.66-9.79 5.66a44.69 44.69 0 0 1-6.66-.53 15.16 15.16 0 0 1 1.77-6 9.43 9.43 0 0 1 8.17-4.91z" /></g></g></svg>);
}

export const TrelloIcon: React.FC<SvgIconProps & IconProps> = ({ size = 20, ...props }) => {
  return (<svg {...props}
    width={size} height={size} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a"><stop stopColor="#0091E6" offset="0%" /><stop stopColor="#0079BF" offset="100%" /></linearGradient></defs><rect fill="url(#a)" width="256" height="256" rx="25" /><rect fill="#FFF" x="144.64" y="33.28" width="78.08" height="112" rx="12" /><rect fill="#FFF" x="33.28" y="33.28" width="78.08" height="176" rx="12" /></svg>)
}


export interface TrelloAPI extends Integration {
  getUserBoards(): Promise<TrelloBoard[]>;
  getBoard(boardId: string): Promise<TrelloBoard | null>;
  getList(listId: string): Promise<TrelloList | null>;
  getCard(cardId: string): Promise<TrelloCard | null>;
  getBoardLists(boardId: string): Promise<TrelloList[]>;
  createCard(listId: string, name: string, description?: string): Promise<TrelloCard>;
}

export class AxiosTrelloAPI implements TrelloAPI {
  icon = <TrelloIcon size={20} />
  logo = <TrelloLogo size={20} />
  name = 'Trello';
  apiKey = trelloConfig.apiKey;
  appName = 'Scale App';
  callbackURL = encodeURIComponent(new URL('trello-callback', trelloConfig.callbackURI).toString());
  scope = 'read,write';
  expiration = 'never';
  isConfigured = false;

  disconnectUrl(): string {
    return 'https://trello.com/my/account';
  }

  createOAuthUrl(): string {
    return `https://trello.com/1/authorize?return_url=${this.callbackURL}&expiration=${this.expiration}&name=${this.appName}&scope=${this.scope}&response_type=token&key=${this.apiKey}`;
  }

  async isWorking(): Promise<boolean> {
    try {
      await this.getUserBoards();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getBoard(boardId: string): Promise<TrelloBoard> {
    const response = await trelloApi.get(`boards/${boardId}`);
    if (response.status === 401) throw new Error("Invalid Token");
    return response.data;
  }

  async getList(listId: string): Promise<TrelloList> {
    const response = await trelloApi.get(`lists/${listId}`);
    if (response.status === 401) throw new Error("Invalid Token");
    return response.data;
  }


  async getCard(cardId: string): Promise<TrelloCard> {
    const response = await trelloApi.get(`cards/${cardId}`);
    if (response.status === 401) throw new Error("Invalid Token");
    return response.data;
  }

  async getUserBoards(): Promise<TrelloBoard[]> {
    const response = await trelloApi.get('members/me/boards');
    if (response.status === 401) throw new Error("Invalid Token");
    return response.data;
  };

  async createCard(listId: string, name: string, description?: string): Promise<TrelloCard> {
    try {
      const response = await trelloApi.post('cards', {
        idList: listId,
        name,
        desc: description,
      });
      console.log("Card Created", response.data);
      if (response.status === 401) throw new Error("Invalid Token");
      return response.data;
    } catch (error) {
      console.error('Error creating Trello card:', error);
      throw error;
    }
  };

  async getBoardLists(boardId: string): Promise<TrelloList[]> {
    try {
      const response = await trelloApi.get(`boards/${boardId}/lists`);
      if (response.status === 401) throw new Error("Invalid Token");
      return response.data;
    } catch (error) {
      console.error('Error fetching lists for board:', error);
      throw error;
    }
  };
}

export class MockTrelloAPI implements TrelloAPI {


  name: string = "Mock Trello"
  icon = <TrelloIcon size={20} />
  logo = <TrelloLogo size={20} />
  isConfigured: boolean = true;

  returnedBoards: { [key: string]: TrelloBoard } = {};
  returnedLists: Map<string, TrelloList[]> = new Map();
  isWorkingValue: boolean = true;

  isWorking(): Promise<boolean> {
    return Promise.resolve(this.isWorkingValue);
  }
  createOAuthUrl(): string {
    throw new Error("Method not implemented.");
  }
  disconnectUrl(): string {
    throw new Error("Method not implemented.");
  }
  getUserBoards(): Promise<TrelloBoard[]> {
    return Promise.resolve(Object.values(this.returnedBoards));
  }
  getBoardLists(boardId: string): Promise<TrelloList[]> {
    const boards = this.returnedLists.get(boardId)
    return Promise.resolve(boards || []);
  }

  getBoard(boardId: string): Promise<TrelloBoard | null> {
    return Promise.resolve(this.returnedBoards[boardId]);
  }
  getList(listId: string): Promise<TrelloList | null> {
    throw new Error("Method not implemented.");
  }

  getCard(cardId: string): Promise<TrelloCard | null> {
    let foundCard = null;

    Array.from(this.returnedLists.values()).forEach(list => list.forEach(card => {
      if (card.id === cardId) foundCard = card;
    }));
    return Promise.resolve(foundCard);
  }

  createCard(listId: string, name: string, description: string = ""): Promise<TrelloCard> {
    console.log(listId, name, description);
    return Promise.resolve({
      id: "new Date().toDateString()",
      name: name,
      desc: description,
      shortUrl: `<short url to trello card '${name}'>`
    });
  }
}