import { User } from "@auth0/auth0-react";
import { Button, Grid, Typography } from "@mui/material";
import { UserIdentity, useRedirect } from "react-admin";
import { Step } from "react-joyride"

const commonStep: Partial<Step> = {
  placement: 'center',
  floaterProps: {
    disableAnimation: true,
  },
}

export const createDashboardTutorialSteps = (identity: User | undefined, setOnboardingRun: (state: boolean) => void, redirect: any): Step[] => {
  return [{
    ...commonStep,
    content: (
      <div>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} xl={8}>
            <div>
              <img alt="Dashboard Tutorial" src="/dashboard-tutorial.png" width={550} />
            </div>
          </Grid>
          <Grid item xs={12} xl={4}>
            <div style={{ textAlign: 'left' }}>
              <Typography gutterBottom>
                <b>Hi there {identity?.given_name}</b>
                <span role="img" aria-label="hello">👋</span>
              </Typography>
              <Typography>Scale is a growth portfolio management platform for large corporations. Scale supports the teams and leadership to launch more new ideas to market faster.</Typography>
              <Typography>Would you like to have a tour to see how it works?</Typography>
            </div>
          </Grid>
        </Grid>
      </div >
    ),
    target: '.scaleDashboard',
    title: 'Welcome to Scale'
  },
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography>
          <p>Scale works in three simple steps:</p>
          <p><strong>Assess</strong></p>
          <p><strong>Analyze</strong></p>
          <p><strong>Plan</strong></p></Typography>
      </div>
    ),
    target: '.scaleDashboard',
    title: 'Scale basics 1/4'
  },
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography gutterBottom><strong>Assess</strong></Typography>
        <Typography>Team discusses the objectives and required evidence to understand how far they currently are.</Typography>
      </div>
    ),
    target: '.scaleDashboard',
    title: 'Scale basics 2/4'
  },
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography gutterBottom><strong>Analyze</strong></Typography>
        <Typography>Analyzing the results helps team to understand their strenghts, weaknesses and how to get support from stakeholders.</Typography>
      </div>
    ),
    target: '.scaleDashboard',
    title: 'Scale basics 3/4'
  },
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography gutterBottom><strong>Plan</strong></Typography>
        <Typography>Scale platform will automatically prioritize next objectives for your project. This helps you to plan how to complete these objectives and level up your project.</Typography>
      </div>
    ),
    target: '.scaleDashboard',
    title: 'Scale basics 4/4'
  },
  /*
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography>Let's have a look at the dashboard next.</Typography>
      </div>
    ),
    target: '.scaleDashboard',
    title: 'Dashboard'
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>This is your portfolio overview.</Typography>
        <Typography>Here you can track progress of your growth portfolio, see how the potential evolves and new ideas grow into scalable business!</Typography>
      </div>
    ),
    spotlightPadding: 20,
    target: '.dashboardContainer',
    title: 'Scale dashboard',
    disableOverlay: true
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>Let's enable the demo data for this tutorial - click the toggle!</Typography>
      </div>
    ),
    placement: 'left',
    target: '.toggleDemoData',
    title: 'Demo data',
    disableOverlay: false,
    spotlightClicks: true
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>Here is the summary of the most important portfolio KPIs.</Typography>
      </div>
    ),
    placement: 'bottom',
    spotlightPadding: 20,
    target: '#KPIs',
    title: 'Portfolio KPIs',
    disableOverlay: true
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>This visualisation helps you to follow the progress of the initiatives and analyse your portfolio structure.</Typography>
      </div>
    ),
    placement: 'top',
    target: '.bubbleChart',
    title: 'Portfolio visualisation',
    disableOverlay: false
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>Innovation is about constant experimentation. This helps you to track whether you have enough ideas in the pipeline.</Typography>
      </div>
    ),
    placement: 'top',
    target: '.pipeline',
    title: 'Innovation pipeline',
    disableOverlay: false
  },
  {
    ...commonStep,
    content: (
      <div>
        <Typography>Finally, on the bottom we have a list summarising all your portfolio projects.</Typography>
      </div>
    ),
    placement: 'top',
    target: '.projectList',
    title: 'Project list',
    disableOverlay: false
  },
  */
  {
    ...commonStep,
    content: (
      <div style={{ textAlign: 'center' }}>
        <Typography gutterBottom><b>That's it!</b></Typography>
        <Typography style={{ paddingBottom: '20px' }}>Now let's get started by creating your first project.</Typography>
        <Button variant="text" onClick={() => setOnboardingRun(false)} style={{ marginRight: '20px' }}>Skip</Button>
        <Button variant="contained" color="primary" onClick={() => redirect("scale_business/create")}>Create project</Button>
      </div>
    ),
    spotlightPadding: 20,
    disableOverlay: false,
    target: '.scaleDashboard',
    title: 'Tutorial completed! 🎉',
    hideBackButton: true,
    showSkipButton: true,
    hideFooter: true
  },
  ];
}