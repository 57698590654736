import { useEffect, useRef, useState } from "react";

/**
 * Simple Hook to return width of an referenced component
 * @returns 
 */
const useComponentWidth = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    // Update width on mount and on resize  
    updateWidth();
    // window.addEventListener("resize", updateWidth);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return { ref, width };
};

export default useComponentWidth;