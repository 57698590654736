import { Button, Grid, Switch, Dialog, DialogActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import React, { useContext, useState, useEffect } from 'react';
import { ApiScoreByDay, BusinessHistory } from '../model/ScaleTypes';
import { ProjectsInPhases } from '../components/ProjectsInPhases';
import Joyride, { Step } from 'react-joyride';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { defineCalculatedProperty, flattenGroupBy, flattenGroupByKeepGroup, groupByReduce, latest, latestBefore } from '../util/ScaleUtils';
import { PortfolioBubbleCard } from '../components/cards/PortfolioBubbleCard';
import { Title, useGetList, useRedirect, useTranslate } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { SettingsContext } from '../state/SettingsContext';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import useMaturityModel from '../state/useMaturityModel';
import PropertySumStat from '../components/cards/PropertySumStat';
import { NewUserWelcome } from '../components/cards/NewUserWelcome';
import { ComparisonPeriodDropdown } from '../components/ComparisonPeriodDropdown';
import { createDashboardTutorialSteps } from '../configuration/tutorials';
import { FullScreenButton } from '../components/FullScreenButton';
import { BusinessListCard } from './BusinessListView';
import dayjs from '../configuration/configuredDayjs';

const TUTORIAL_VIDEO_URL = "https://www.youtube.com/embed/crv-INGEAQE";

type VideoIframeProps = {
  title: string;
  src: string;
};

const VideoIframe = React.memo(({ title, src }: VideoIframeProps) => {
  const aspectRatio = 16 / 9;

  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  let width = viewportWidth * 0.8;
  let height = width / aspectRatio;
  if (height > viewportHeight * 0.8) {
    height = viewportHeight * 0.8;
  }
  return (
    <iframe
      style={{ width: `100%`, height: `${height}px` }}
      src={src}
      title={title}
    />
  );
});


const Dashboard = (props: any) => {
  document.title = "Scale SaaS - Dashboard"

  const redirect = useRedirect();
  const translate = useTranslate();

  const settings = useContext(SettingsContext);
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const [period, setPeriod] = useState(3);

  const { permissions, isLoading: identityLoading } = useScalePermissions();

  // ONBOARDING
  const [run, setOnboardingRun] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setSteps(createDashboardTutorialSteps(permissions.user, setOnboardingRun, redirect));
  }, [permissions.user]);
  // ONBOARDING END

  const { data: businessHistories, isLoading: businessHistoriesLoading } = useGetList(
    'api_history_business', {
    sort: { field: 'modifiedAt', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
    filter: {
      "deleted@_neq": true,
      "businessId@_in": permissions.isLimitedAccess ? permissions.allowedBusinessIds : undefined
    }
  })

  const { data: scoresByDay, isLoading: scoresByDayLoading } = useGetList(
    'api_averageScoreByDay', {
    sort: { field: 'periodDate', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  }
  );

  if (mmLoading || identityLoading || businessHistoriesLoading || scoresByDayLoading) {
    return <LoadingAnimation loadingText='Loading portfolio data' />
  }

  if (!maturityModel) return <p>Failure Loading maturity model.</p>

  const onClick = function (series: any, dataPoint: BusinessHistory) {
    if (dataPoint === null) return;
    var closestID = dataPoint.businessId;
    redirect("show", "api_business", closestID);
    return;
  };

  const pastDate = dayjs().subtract(period, 'month');
  const scoresById = groupByReduce(scoresByDay! as ApiScoreByDay[], 'businessId');
  const scoresNow = flattenGroupByKeepGroup(scoresById, group => latest(group));
  const scoresPast = flattenGroupByKeepGroup(scoresById, group => latestBefore(pastDate.toDate(), group, "periodDate"));

  /**
   * Adds missing properties to BusinessHistory, so that it has the same data as BusinessExtended
   * @param item BusinessHistory item, 
   * @param scores 
   * @returns 
   */
  function decorateProperties(item: BusinessHistory | null, scores: { [key: string]: ApiScoreByDay | null }) {
    if (!item) return;
    item.organizationUnitName = item.organizationUnit.name;

    const averageScore = scores[item.businessId!];
    if (averageScore) {
      item.score = averageScore.score;
      item.averageScoreByDay = averageScore;
      defineCalculatedProperty(item, "weightedPotential", () => item.score / maturityModel!.maxLevel * Number(item.businessPotential));
      defineCalculatedProperty(item, "scoreModifiedAt", () => averageScore.periodDate);
    }
  }

  const historiesById = groupByReduce(businessHistories as BusinessHistory[], 'businessId');
  const nowValues = flattenGroupBy(historiesById, group => latest(group, true)).filter(business => business?.status === 'active');
  const pastValues = flattenGroupBy(historiesById, group => latestBefore(pastDate.toDate(), group, "modifiedAt", true)).filter(business => business?.status === 'active');

  nowValues.map(item => decorateProperties(item, scoresNow));
  pastValues.map(item => decorateProperties(item, scoresPast));

  const dataInArray: BusinessHistory[] = (nowValues as BusinessHistory[]);

  return (
    <div className="scaleDashboard">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'} // 'md' is approximately 80% of the viewport size
      >
        <VideoIframe src={TUTORIAL_VIDEO_URL} title="Tutorial Video" />

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>

      </Dialog>
      <Title title="Dashboard" />
      <Joyride
        //callback={this.handleJoyrideCallback}
        continuous={true}
        //getHelpers={this.getHelpers}
        run={run}
        scrollToFirstStep={true}
        showSkipButton={true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            width: 'auto'
          },
        }}
      />
      <div className="dashboardContainer">
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={6} sm={6} md={6}>
            <ComparisonPeriodDropdown period={period} setPeriod={setPeriod} />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Grid container alignItems={"center"} justifyContent={"end"} padding={0}>
              <Grid item>
                {!permissions.isLimitedAccess &&
                  <Typography textAlign="right">Show demo data: <Switch
                    checked={settings.interfaceSettings.demoMode}
                    onChange={(event, checked) => settings.setInterfaceSetting('demoMode', checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></Typography>
                }
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                  Tutorial Video
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} propertyName="businessPotential" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} propertyName="weightedPotential" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} propertyName="count" changeMode='absolute' default={1} formatter={(value) => value.toFixed(0)} />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} propertyName="score" changeMode='absolute' default={1} formatter={(value) => value.toFixed(1)} />
          </Grid>
          {dataInArray.length === 0 &&
            <Grid item sm={12}>
              <NewUserWelcome identity={permissions.user} handleDemoModeChange={(event) => settings.setInterfaceSetting('demoMode', event.target.checked)} showDemoData={settings.interfaceSettings.demoMode} />
            </Grid>
          }

          {!permissions.isLimitedAccess && <>
            <Grid item xs={12} sm={12} md={12}>
              <PortfolioBubbleCard
                businessData={nowValues as BusinessHistory[]}
                comparisonBusinessData={pastValues as BusinessHistory[]}
                maturityModel={maturityModel} onClick={onClick} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <Card className="pipeline">
                <CardHeader title="Projects in innovation pipeline" action={<InfoIconTooltip title={translate('pos.definitions.pipelineProjects')} />} />
                <ProjectsInPhases maturityModel={maturityModel} maxItemsToList={0} data={dataInArray} />
              </Card>
            </Grid>
          </>
          }
          <Grid item xs={12} sm={12} md={12}>
            <BusinessListCard data={dataInArray} initialNumberOfRows={25} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}><FullScreenButton /></Grid>
          <Grid item xs={4} sm={4} md={4} />
          <Grid item xs={4} sm={4} md={4} />
        </Grid>
      </div>
    </div >
  );
}

export default withIdentityChecks(Dashboard);

