import { FC } from 'react';
import {
    CreateProps, DeleteWithConfirmButton, Edit, SaveButton, Toolbar, useNotify, useRecordContext, useRedirect,
} from 'react-admin';
import { Plan } from '../../model/ScaleTypes';
import { PlanForm, transformPlan } from './PlanForm';
import { CancelButton } from '../../components/BackButton';

export const PlanEdit: FC<CreateProps> = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data: Plan) => {
        notify(`generic.updated`, { type: "success", messageArgs: { itemType: "Milestone Plan" } });
        redirect('show', '/api_business', data.businessId)
    }

    const onError = (error: any) => {
        notify(`Could not save edit: ${error.message}`)
    };

    const FormToolBar = (props: any) => {
        const record = useRecordContext();
        return (
            <Toolbar {...props}>
                <SaveButton type="button" />
                <CancelButton />
                <DeleteWithConfirmButton mutationOptions={{
                    onSuccess: () => {
                        notify('generic.deleted', { type: "success", messageArgs: { itemType: "Milestone Plan" } });
                        redirect('show', '/api_business', record.businessId);
                    }
                }}
                    confirmTitle="Delete milestone with '%{name}' target date?"
                    translateOptions={{ name: `${record.targetDate}` }}
                />
            </Toolbar>
        );
    }
    return (
        <Edit title="Plan"
            mutationOptions={{ onSettled: () => { console.log("settled!") }, onMutate: () => { console.log("mutate!") }, onSuccess, onError }}
            mutationMode="pessimistic"
            transform={transformPlan}
        >
            <PlanForm toolbar={<FormToolBar />} />
        </Edit>
    );
};

export default PlanEdit;


