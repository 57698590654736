import React from 'react';
import Confetti from 'react-confetti';
import { randomChoiceValue } from '../util/ScaleUtils';

interface ConfettiProps {
  partyOn: boolean;
  setParty: (value: boolean) => void;
}

const ConfettiComponent: React.FC<ConfettiProps> = ({ partyOn, setParty }) => {
  const confettiWidth = window.innerWidth;
  const confettiHeight = window.innerHeight;

  const spiral = (ctx: CanvasRenderingContext2D) => {
    ctx.beginPath();
    for (let i = 0; i < 22; i++) {
      const angle = 0.35 * i;
      const x = (0.2 + (1.5 * angle)) * Math.cos(angle);
      const y = (0.2 + (1.5 * angle)) * Math.sin(angle);
      ctx.lineTo(x, y);
    }
    ctx.stroke();
    ctx.closePath();
  };

  function star(ctx: CanvasRenderingContext2D): void {
    const spikes = 5;
    const outerRadius = Math.random() * 10 + 5;
    const innerRadius = outerRadius / 2;
    const cx = 0;
    const cy = 0;

    ctx.beginPath();
    let rot = Math.PI / 2 * 3;
    let x = cx;
    let y = cy;
    const step = Math.PI / spikes;

    ctx.moveTo(cx, cy - outerRadius);
    for (let i = 0; i < spikes; i++) {
      x = cx + Math.cos(rot) * outerRadius;
      y = cy + Math.sin(rot) * outerRadius;
      ctx.lineTo(x, y);
      rot += step;

      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      ctx.lineTo(x, y);
      rot += step;
    }
    ctx.lineTo(cx, cy - outerRadius);
    ctx.stroke();
    ctx.closePath();
  }

  const random = Math.random();
  const shape = random > 0.8 ? star : random > 0.6 ? spiral : undefined;

  const goldenColors = ["darkgoldenrod", "gold", "goldenrod", "lightgoldenyellow", "orange", "peru", "yellow"];
  const standardColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548'];

  const colors = Math.random() > 0.5 ? goldenColors : standardColors;

  interface ConfettiPhysics {
    confettiSource?: { x: number, y: number, h: number, w: number }
    gravity?: number,
    initialVelocityX?: number,
    initialVelocityY?: number
  }

  const bottomLeft: ConfettiPhysics = {
    confettiSource: { x: -20, y: confettiHeight * 3 / 4, w: 0, h: confettiHeight / 4 },
    initialVelocityX: 10,
    initialVelocityY: 40,
  };

  const bottomRight: ConfettiPhysics = {
    confettiSource: { x: confettiWidth + 20, y: confettiHeight * 3 / 4, w: 0, h: confettiHeight / 4 },
    initialVelocityX: -10,
    initialVelocityY: 40,
  };

  const top: ConfettiPhysics = {
    confettiSource: { x: 0, y: -20, w: confettiWidth, h: 0 }
  };

  const physics = randomChoiceValue([
    { weight: 50, value: top },
    { weight: 25, value: bottomLeft },
    { weight: 25, value: bottomRight },
  ])

  return (
    <Confetti
      {...physics}
      run={partyOn}
      numberOfPieces={200}
      tweenDuration={1000}
      recycle={false}
      onConfettiComplete={confetti => {
        setParty(false);
        confetti?.reset();
      }}
      drawShape={shape}
      colors={colors}
      wind={0}
      width={confettiWidth}
      height={confettiHeight}
      style={{ position: 'fixed' }}
    />
  );
};

export default ConfettiComponent;
