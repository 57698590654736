import React, { useEffect } from 'react';
import { useRedirect } from 'react-admin';

const Callback = () => {
  const redirect = useRedirect();

  useEffect(() => {
    const token = new URLSearchParams(window.location.hash.substring(1)).get('token');

    if (token) {
      localStorage.setItem('trello_token', token);
      console.log('Token successfully stored:', token);
      redirect('/integrations');
    } else {
      console.error('Token not found in URL fragment.');
    }
  });
  
  return (
    <div>
      <h2>Trello Callback</h2>
      <p>Processing authorization... If this page stays more than a few seconds, there has been a problem obtaining the token from Trello.</p>
    </div>
  );
}

export default Callback;
