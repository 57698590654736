import React from 'react';
import Plot from 'react-plotly.js';

import { BusinessExtended, MaturityPhase } from '../../model/ScaleTypes';
import { ensureDefault, groupByReduceFunctionMultiple, splitStringBalanced, } from '../../util/ScaleUtils';
import { UsesBusinessHistoryDataProps, UsesMaturityModelProps } from './ScaleGraphTypes';
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import { CARD_LIGHT, getTextColorForBackground, SET_COLORS } from '../Colors';
import { PlotMouseEvent } from 'plotly.js';
import { Rectangle, convertSVGImageToDataURI, estimateTextWidth, packRectangles } from '../../util/GuiUtils';
import { Alert } from '@mui/material';
import { useTranslate } from 'react-admin';
import { CurrencyFormat } from '../../configuration/configuration';
import currency from 'currency.js';

const svgArrow = `<svg viewBox="0 0 500 250" xmlns="http://www.w3.org/2000/svg">
<g transform="matrix(1.0574270486831665, 0, 0, 1.0574270486831665, -23.169916152954087, -141.345947265625)" style="">
  <path style="stroke: rgba(56, 164, 212, 0); fill: rgb(160, 203, 255);" d="M 22.071 138.721 C 131.404 193.68 331.561 236.553 411.558 236.553 L 411.661 265.252 C 331.783 265.252 131.715 304.099 21.97 363.762 L 22.071 138.721 Z"/>
  <path style="stroke: rgba(56, 164, 212, 0); fill: rgb(160, 203, 255);" d="M 411.326 217.769 L 474.615 250.713 L 411.722 283.1 L 411.326 217.769 Z"/>
</g>
</svg>`;

const striped = `
<svg viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g transform="translate(100,100)">
<g transform="rotate(45)">
<g transform="translate(-100,-100)">
  <defs>
    <pattern id="pattern-0-0" patternTransform="matrix(1, 0, 0, 1, 87.499931, 87.500075)" xlink:href="#pattern-0"/>
    <pattern x="0" y="0" width="25" height="25" patternUnits="userSpaceOnUse" viewBox="0 0 100 100" id="pattern-0">
      <rect x="0" y="0" width="50" height="100" style="fill: white;"/>
    </pattern>
  </defs>
  <ellipse style="fill-rule: nonzero; paint-order: stroke; fill: url('#pattern-0-0'); stroke-miterlimit: 7.32; stroke-width: 0px; transform-origin: 0px 0px;" cx="100" cy="100" rx="100" ry="100"/>
  </g>
  </g>
  </g>
</svg>`;

const bgcolors = ['#E9EDFD', '#A5B8F6', '#274FEC', '#1838B8', '#0F257A']

function phaseArrowSvg(phase: MaturityPhase): string {
  return `M ${phase.firstLevel},1.06 L ${phase.firstLevel + 0.1},1.085 L ${phase.firstLevel},1.11 
               L ${phase.lastLevel - 0.1},1.11 L ${phase.lastLevel},1.085, L ${phase.lastLevel - 0.1},1.06 Z `
}

export interface PortfolioMilestoneChartProps extends UsesMaturityModelProps, UsesBusinessHistoryDataProps {
  levelRange: [number, number];
  width?: string;
  height?: string;
  itemFontSize?: number;
  bgColor?: string;
  onClickDataPoint?: (series: any, businessId: BusinessExtended) => void;
}

type Swimlane<T> = {
  label: string,
  labelWidth: number,
  data: T[],
  sublanes: {
    businesses: T[],
  }[],
  swimlaneStart: number
}

type CircleElement = SVGCircleElement;
type TextElement = SVGTextElement;

interface DOMRectReadOnly {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * Primary UI component for user interaction
 */
export const PortfolioMilestoneChart: React.FC<PortfolioMilestoneChartProps> = ({
  maturityModel,
  data,
  itemFontSize = 10,
  levelRange = [0, maturityModel?.maxLevel || 1],
  bgColor,
  onClickDataPoint,
  ...props
}) => {
  const translate = useTranslate();
  if (!data || data.length === 0) return <InsertChartOutlinedIcon fontSize='large' />
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>

  const businesses: BusinessExtended[] = data;

  //const businessesByGroup = groupByReduce(businesses, 'organizationUnitName');
  const businessesByGroup = groupByReduceFunctionMultiple(businesses, (item) => {
    return ensureDefault(item.tags, ['']);
  });

  // @TODO: Fix the sizing of this component, and also the header etc.
  var height = 500;
  const width = 1000;

  const standardMargin = 50; // margins are where the labels are written
  const organizationUnitNames = Object.keys(businessesByGroup).sort().reverse(); // need to be sorted reversed as the swimlanes go from bottom to up
  const plotAreaWidth = width - standardMargin * 3;

  const swimlaneLabelFontSize = itemFontSize * 1.2;

  let swimlaneStart = 0;

  function createSwimlane(name: string, businessGroup: BusinessExtended[]): Swimlane<BusinessExtended> {
    const splitNames = splitStringBalanced(name, 15);

    const businessesAsRectangles: (BusinessExtended & Rectangle)[] = businessGroup.map(business => {
      return {
        ...business,
        x: (business.score - levelRange[0]) / (levelRange[1] - levelRange[0]) * plotAreaWidth,
        y: 0,
        width: estimateTextWidth(business.name, itemFontSize) + itemFontSize * 2, // this multiplier is to estimate the circle size and the space,
        height: itemFontSize,
      }
    });

    const distributed = packRectangles(businessesAsRectangles); //distribute(businessGroup, Math.ceil(businessGroup.length / maxPerLane), 'score');
    //const distributed = distribute(businessGroup, Math.ceil(businessGroup.length / 3), 'score');
    const swimlane: Swimlane<BusinessExtended> = {
      label: splitNames.join("<br />"),
      data: businessGroup,
      labelWidth: estimateTextWidth("0123456789012345", swimlaneLabelFontSize),
      sublanes: [],
      swimlaneStart
    }
    distributed.forEach(laneItems => swimlane.sublanes.push({ businesses: laneItems }));
    swimlaneStart += swimlane.sublanes.length;
    return swimlane;
  }

  const swimlanes = organizationUnitNames.map(name => createSwimlane(name, businessesByGroup[name]));
  const numberOfSublines = swimlanes.reduce((total, group) => total + group.sublanes.length, 0) || 5;

  const plotAreaHeight = height ? height - 2 * standardMargin : numberOfSublines * itemFontSize * 2.5;
  height = plotAreaHeight + standardMargin * 2

  const heightPerSubLine = plotAreaHeight / numberOfSublines;

  let verticalNamesOk = true;
  swimlanes.forEach(lane => {
    if (lane.labelWidth > lane.sublanes.length * heightPerSubLine) verticalNamesOk = false
  });

  const circleSize = itemFontSize * 1.2;

  const shapes: Partial<Plotly.Shape>[] = [];
  const traces: Partial<Plotly.PlotData>[] = [];
  const annotations: Partial<Plotly.Annotations>[] = [];
  const images: Partial<Plotly.Image>[] = [];

  // background
  images.push(
    {
      source: convertSVGImageToDataURI(svgArrow),
      xref: 'paper',
      yref: 'paper',
      x: 0,
      y: 1,
      sizex: 1,
      sizey: 1,
      sizing: 'stretch',
      opacity: 0.20,
      layer: 'below'
    });

  swimlanes.forEach((swimlane, index) => {
    const x: number[] = [];
    const y: number[] = [];
    const ids: string[] = [];
    const text: string[] = [];
    const circleSizes: number[] = [];
    const markerLines: number[] = [];
    const customdata: string[] = [];


    swimlane.sublanes.forEach((lane, laneIndex) => lane.businesses.forEach(business => {
      x.push(business.score);
      y.push(swimlane.swimlaneStart + laneIndex + 0.5);
      ids.push(business.id);
      text.push(business.name);
      circleSizes.push(
        Math.max(circleSize, circleSize * Math.log10(business.businessPotential / 500000) / Math.log10(4))
      )
      markerLines.push(business.businessPotential > 0 ? 1 : 0);
      customdata.push(business.businessPotential > 0 ? currency(business.businessPotential, CurrencyFormat).format() : "Not yet available")

      if (business.businessPotential > 0) {
      } else {
        // make it dashed
        images.push(
          {
            source: convertSVGImageToDataURI(striped),
            xref: 'x',
            xanchor: 'center',
            yanchor: 'middle',
            yref: 'y',
            x: business.score,
            y: swimlane.swimlaneStart + laneIndex + 0.5,
            sizex: 0.26,
            sizey: 0.26,
            sizing: 'contain',
            opacity: 1,
            layer: 'above'
          }
        );
      }


    }));

    traces.push({
      y,
      x,
      ids,
      mode: "text+markers",
      marker: {
        size: circleSizes,
        sizemode: "diameter",
        opacity: 0.6,
        color: SET_COLORS[index],
        line: {
          width: markerLines,
          color: 'black',
          //@ts-ignore
          dash: 'dash'
        },
      },
      text,
      customdata,
      textposition: "middle right",
      textfont: {
        size: itemFontSize,
      },
      type: 'scatter',
      name: swimlane.label,
      hovertemplate: '<b>%{text}</b><br>' +
        '<br>Potential: %{customdata}' +
        '<br>Score: %{x:.1f}' +
        '<extra></extra>',
    });


    shapes.push({
      type: 'line',
      xref: 'paper',
      yref: 'y',
      y0: swimlane.swimlaneStart,
      x0: 0,
      x1: 1,
      y1: swimlane.swimlaneStart,
      line: {
        color: '#cccccc',
        width: 1
      }
    });
  });

  maturityModel.phases.sort((a, b) => a.lastLevel - b.lastLevel);
  maturityModel.phases.forEach((phase, index) => {
    const color = bgcolors[Math.min(index, bgcolors.length - 1)];
    shapes.push(
      {
        type: 'path',
        path: phaseArrowSvg(phase),
        line: {
          color: color,
          width: 1,
        },
        fillcolor: color,
        xref: 'x', // Use 'paper' for positioning relative to the entire drawing area
        yref: 'paper'
      });
    annotations.push({
      text: phase.name,
      align: 'center',
      valign: 'top',
      font: {
        family: 'Arial',
        size: 12,
        color: getTextColorForBackground(color)
      },
      x: (phase.lastLevel - phase.firstLevel) / 2 + phase.firstLevel,
      y: 1.11,
      showarrow: false,
      xref: 'x',
      yref: 'paper'
    })
  });

  const layout: Partial<Plotly.Layout> = {
    showlegend: false,
    // swimline labels and info
    yaxis: {
      fixedrange: true,
      zeroline: true,
      showgrid: false,
      anchor: 'x',
      side: 'left',
      tickmode: 'array',
      tickvals: swimlanes.map(tick => tick.swimlaneStart + tick.sublanes.length / 2.0),
      ticktext: swimlanes.map(tick => tick.label),
      tickangle: verticalNamesOk ? -90 : 0,
      ticklen: 3,
      tickfont: { size: swimlaneLabelFontSize },
      rangemode: 'tozero',
    },
    xaxis: {
      fixedrange: true,
      tickvals: maturityModel.milestones?.map((milestone, idx) => milestone.level),
      ticktext: maturityModel.milestones?.map((milestone, idx) => milestone.shortName),
      range: levelRange,
      tickangle: 0,
      side: 'top',
      tickcolor: 'darkgray',
      gridcolor: 'lightgray'
    },
    dragmode: 'pan',
    shapes: shapes,
    height,
    width,
    plot_bgcolor: data.length === 0 ? CARD_LIGHT : 'white',
    paper_bgcolor: bgColor,
    margin: { l: verticalNamesOk ? standardMargin : Math.max(...swimlanes.map(lane => lane.labelWidth)), t: standardMargin, b: standardMargin, r: standardMargin }
  };

  if (data.length === 0) {
    annotations.push({
      text: 'Empty data',
      xref: 'paper',
      yref: 'paper',
      x: 0.5,
      y: 0.5,
      showarrow: false,
      font: {
        size: 16,
      }
    })
  }
  layout.annotations = annotations;
  layout.images = images;

  const plotlyData: Plotly.Data[] = [];
  traces.forEach(trace => plotlyData.push(trace));

  var onClickWrapper = function (event: Readonly<PlotMouseEvent>) {
    console.log(event);
    if (onClickDataPoint && event.points.length === 1) {
      const clickedPoint = event.points[0];

      //@ts-ignore
      onClickDataPoint(clickedPoint.data.name, businesses.find((val) => val.id === clickedPoint.id));
    }
  }

  var extras = {};
  if (onClickDataPoint) extras = { onClick: onClickWrapper, ...extras }

  return <Plot data={plotlyData}
    layout={layout}
    style={{ height, width }}
    config={{ displayModeBar: false, responsive: false }}
    {...extras}
  />

}; 