import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useTranslate } from 'react-admin';
import { BusinessHistory, MaturityModel } from '../../model/ScaleTypes';
import { PortfolioBubbleChart } from '../charts/PortfolioBubbleChart';
import InfoIconTooltip from '../InfoIconTooltip';


interface PortfolioBubbleCardProps {
  businessData: BusinessHistory[],
  comparisonBusinessData?: BusinessHistory[],
  maturityModel: MaturityModel,
  onClick: (series: any, dataPoint: BusinessHistory) => void
}

export const PortfolioBubbleCard = (props: PortfolioBubbleCardProps) => {
  const { businessData, comparisonBusinessData, maturityModel, onClick } = props;
  const [bubbleColorProperty, setBubbleColorProperty] = useState("name");
  const translate = useTranslate();
  return (
    <Card className="bubbleChart" >
      <CardHeader title="Portfolio View" action={<InfoIconTooltip title={translate('pos.definitions.portfolioBubbleChart')} />} />
      <CardContent><Typography>Total of {businessData.length} Cases in the portfolio. You can zoom the picture and double click on a bubble to open it.</Typography></CardContent>
      <PortfolioBubbleChart
        maturityModel={maturityModel}
        seriesProperty={bubbleColorProperty as keyof BusinessHistory}
        data={businessData}
        comparisonData={comparisonBusinessData}
        onClickDataPoint={onClick} />
      <CardContent>

        <FormControl component="fieldset">
          <FormLabel component="legend">Visualisation</FormLabel>
          <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={bubbleColorProperty} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBubbleColorProperty((event.target as HTMLInputElement).value);
          }}>
            <FormControlLabel value="name" control={<Radio color='primary' />} label="Business" />
            <FormControlLabel value="organizationUnitName" control={<Radio color='primary' />} label="Organization" />
            <FormControlLabel value="status" control={<Radio color='primary' />} label="Status" />
            <FormControlLabel value="horizon" control={<Radio color='primary' />} label="Horizon" />
            <FormControlLabel value="businessModel" control={<Radio color='primary' />} label="Business model" />
          </RadioGroup>
        </FormControl>

      </CardContent>
    </Card>);
}