import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import ActionDelete from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';

interface DeleteProjectButtonProps {
  projectName: string;
  onConfirmDelete: () => void;
}

export const DeleteProjectButton: React.FC<DeleteProjectButtonProps> = ({ projectName, onConfirmDelete }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const notify = useNotify();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputValue('');
  };

  const handleDelete = () => {
    if (inputValue === projectName) {
      onConfirmDelete();
      handleClose();
    } else {
      notify("warnings.delete.project_name_not_matching", { type: "warning" });
    }
  };

  return (
    <div>
      <Button startIcon={<ActionDelete />} variant="text" color="secondary" onClick={handleOpen}>Delete Project</Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Project Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To confirm the deletion of this project, please type the project name (<strong>{projectName}</strong>) below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Confirm Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteProjectButton;
