import React, { useState } from 'react';
import Plot from 'react-plotly.js'
import { BusinessExtended, MaturityModel, defaultMaturityModel } from '../../model/ScaleTypes';
import { findMin, groupByReduce, flattenGroupBy } from '../../util/ScaleUtils';
import { theme } from '../../layout/theme';
import { Button, Dialog, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from '@mui/material';
import { useNotify, useRedirect } from 'react-admin';
import { useCreate, useUpdate } from 'react-admin';
import QuickCreate from '../../resources/scale_business/QuickCreate';
import { SET_COLORS } from '../Colors';


const NumberInput = styled(TextField)({
  '& input[type="number"]': {
    "padding-right": "5px",
  },

  "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button": {
    "opacity": 1,
    "margin-left": "0px",
    "margin-right": "-3px",
  },
});

export interface EarlyInsightsChartProps {
  /**
   * Maturity Model information
   */
  maturityModel?: MaturityModel;
  /**
   * The property name to use to distinguish different series that canbe filtered-
   */
  seriesProperty: string;
  /**
   * data containing BusienssExtended Records, data can contain duplicates of 
   */
  data: BusinessExtended[];
  /**
   * Returns the closest data point from the data series when clicked.
   */
  onClickDataPoint?: (series: any, dataPoint: BusinessExtended) => void;
}

function budgetToNumber(value: any) {
  return value ? value : 100000;
}

/**
 * Time until target date, if the time has already passed MAX_SAFE_INTEGER is returned  
 * @param targetDate date which is the last
 */
const timeUntil = (targetDate: Date) => {
  const closestBeforeEqualPredicate = (value: BusinessExtended) => {
    const property = "scoreModifiedAt";
    if (value == null || value[property] === undefined) return Number.MAX_SAFE_INTEGER;
    //@ts-ignore
    const diff = targetDate.getTime() - new Date(value[property]).getTime();
    return diff >= 0 ? diff : Number.MAX_SAFE_INTEGER;
  }
  return closestBeforeEqualPredicate;
}

/**
 * Primary UI component for user interaction
 */
export const EarlyInsightsChart: React.FC<EarlyInsightsChartProps> = ({
  maturityModel = defaultMaturityModel,
  seriesProperty = "",
  data = [],
  onClickDataPoint,
  ...props
}) => {

  const [businessData, setBusinessData] = useState<BusinessExtended[]>([...data].sort((a, b) => a.name.localeCompare(b.name)));
  const [chartData, setChartData] = useState<Plotly.Data[]>(calculatePData(businessData, seriesProperty));
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const handleCloseDialog = (newProject: any) => {
    if (newProject.name) businessData.push(newProject);
    setDialogOpen(false);
    redirect('/earlyInsights');
  }

  React.useEffect(() => {
    setChartData(calculatePData(businessData, seriesProperty))
  }, [businessData, seriesProperty]);

  const redirect = useRedirect();

  const [updateEarlyInsights] = useUpdate();
  const [createEarlyInsights] = useCreate();
  const notify = useNotify();

  // group data by businessId in case it happens to be historical data
  const dataByBusinessId = groupByReduce(data, 'businessId');
  if (
    Object.keys(dataByBusinessId).length === 1 &&
    Object.keys(dataByBusinessId)[0] === 'undefined'
  ) return <p>Data error, missing 'businessId'.</p>

  const config = {
    displayModeBar: false,
    editable: false,
    responsive: false
  }

  // Create Four Field layout

  const layout = scaleBubbleChartLayout();
  const fourField: { 'shapes': any[], 'annotations': any[] } = createFourField();

  //@ts-ignore
  layout.shapes = fourField.shapes;

  //@ts-ignore
  layout.annotations = fourField.annotations;

  const handleInputChange = (row: any, field: 'risk' | 'impact') => (e: any) => {

    if (10 < e.target.valueAsNumber || e.target.valueAsNumber < 0 || isNaN(e.target.valueAsNumber)) {
      return;
    }

    setBusinessData(
      businessData.map((business) => {
        if (business.id !== row.id || isNaN(e.target.valueAsNumber)) {
          return business;
        }
        return { ...business, insights: { ...business.insights, [field]: e.target.valueAsNumber } }
      })
    )
  };

  const saveInputChange = (row: any) => () => {
    if (row.insights?.id) {
      updateEarlyInsights(
        'scale_insights',
        {
          id: row.insights?.id,
          data: {
            risk: row.insights?.risk ?? 0,
            impact: row.insights?.impact ?? 0
          }
        }
      );
      notify("pos.earlyInsights.saved");
    } else {
      createEarlyInsights(
        'scale_insights',
        {
          data: {
            businessId: row.id,
            risk: row.insights?.risk ?? 10,
            impact: row.insights?.impact ?? 0
          }
        }
      );
    }
  };

  //@ts-ignore
  var latestBusinessData = flattenGroupBy(dataByBusinessId, arr => findMin(arr, timeUntil(new Date())));
  var latestBusinessDataBySeries = groupByReduce(latestBusinessData, seriesProperty);

  var onClickWrapper = function (d: any) {
    if (d.points.length === 1) {
      var p = d.points[0];
      // The data received is organized per the series.
      if (onClickDataPoint) onClickDataPoint(p.data.name, latestBusinessDataBySeries[p.data.name][p.pointIndex]);
      return;
    }
  }
  var extras = {};
  if (onClickDataPoint) extras = { onClick: onClickWrapper, ...extras };

  return <Paper elevation={2}>
    <Grid container direction='row-reverse'>
      <Dialog open={isDialogOpen}>
        <div style={{ margin: '0px 25px 0px 25px' }}>
          <QuickCreate resource='scale_business' quickCreate closeDialog={handleCloseDialog} />
        </div>
        <Button variant='text' style={{ margin: '5px' }} onClick={handleCloseDialog}>Close</Button></Dialog>
      <Grid item sm={12} md={8}>
        {/* @ts-ignore */}
        <Plot data={chartData}
          style={{ width: '100%', height: '700px', marginTop: '0px' }}
          // @ts-ignore
          layout={layout} config={config}
          {...extras}
        />
      </Grid>
      <Grid container direction='column' justifyContent='space-around' item sm={12} md={4} style={{ height: 'auto', padding: '10px' }}>
        <TableContainer component={Paper} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Business</strong></TableCell>
                <TableCell align="center"><strong>Risk</strong></TableCell>
                <TableCell align="center"><strong>Impact</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessData.map((row: any, index) =>
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <NumberInput InputProps={{ inputProps: { max: 10, min: 0 } }} type='number' variant='outlined' value={row.insights?.risk ?? 10} onChange={handleInputChange(row, "risk")} onBlur={saveInputChange(row)}>
                    </NumberInput>
                  </TableCell>
                  <TableCell align="center">
                    <NumberInput InputProps={{ inputProps: { max: 10, min: 0 } }} type='number' variant='outlined' value={row.insights?.impact ?? 0} onChange={handleInputChange(row, "impact")} onBlur={saveInputChange(row)} >
                    </NumberInput>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  <Button variant='contained' color='primary' onClick={() => setDialogOpen(true)}>Create project</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Paper>
};

// ******************************** CREATING DATA ********************************
/**
 * Creates a Plotly data set for a series of BusinessExtended data.
 * @param seriesName name for the series (shown in the legend)
 * @param dataForSeries the data set for the series
 * @param maxBudget budget value used for scaling the sizes of the bubbles
 * @param color color to beused
 * @param showLegend true if the legend is to be shown
 * @returns Plotly.Data set of markers
 */
export const createSeriesData = function (seriesName: string, dataForSeries: BusinessExtended[], maxBudget: number, color?: string, showLegend?: boolean): Plotly.Data {
  if (dataForSeries === undefined || dataForSeries.length === 0) return {};
  var risk = dataForSeries.map(function (d: any) { return d.insights?.risk ?? 10; }); // (Math.round(d.score * 10) / 10); })
  var impact = dataForSeries.map(function (d: any) { return d.insights?.impact ?? 0; });
  var budgets = dataForSeries.map(function (d: any) { return budgetToNumber(d.businessPotential); })
  var names = dataForSeries.map(function (d: any) { return d.name; })
  var opacities = dataForSeries.map(function (d: any) { return d.status === 'not tracked' ? 0.3 : 0.7 })

  // https://plotly.com/javascript/bubble-charts/#bubble-size-scaling-on-charts
  var desired_maximum_marker_size = 60;
  return {
    x: risk,
    y: impact,
    text: names,
    mode: 'markers',
    name: seriesName,
    showlegend: showLegend,
    legendgroup: seriesName,
    marker: {
      size: budgets,
      sizemin: 4,
      sizeref: 2.0 * maxBudget / (desired_maximum_marker_size ** 2),
      sizemode: 'area',
      opacity: opacities,
      ...(color && { color: color }),
      line: {
        width: 1,
        ...(color && { color: color })
      }
    }
  };
}

// ******************************** LAYOUT ********************************

const scaleBubbleChartLayout = function () {
  return {
    font: {
      family: theme.typography.fontFamily,
      size: theme.typography.fontSize,
    },
    hovermode: 'closest',
    autosize: true,
    showlegend: true,
    legend: { y: -0.1, x: 0, orientation: 'v', yanchor: 'top' },
    yaxis: {
      type: 'linear',
      range: [0, 10],
      dtick: 1,
      automargin: true,
      showline: true,
      title: 'Impact'
    },
    xaxis: {
      type: 'linear',
      range: [0, 10], // default
      dtick: 1,
      automargin: true,
      showline: true,
      title: 'Risk'

    },
    annotations: [],
    shapes: []
  }
};

function createFourField(): { 'shapes': any[], 'annotations': any[] } {
  // Four field segments START

  const shapes: any[] = [
    // BACKGROUND START
    {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      x0: 0,
      y0: 5,
      x1: 5,
      y1: 10,
      fillcolor: '#2ca02c',
      opacity: 0.2,
      line: {
        width: 0
      }
    },
    {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      x0: 5,
      y0: 5,
      x1: 10,
      y1: 10,
      fillcolor: '#2ca02c',
      opacity: 0.1,
      line: {
        width: 0
      }
    },
    {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      x0: 0,
      y0: 0,
      x1: 5,
      y1: 5,
      fillcolor: '#2ca02c',
      opacity: 0.05,
      line: {
        width: 0
      }
    },
    {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      x0: 5,
      y0: 0,
      x1: 10,
      y1: 5,
      fillcolor: '#2ca02c',
      opacity: 0.025,
      line: {
        width: 0
      }
    },
    // BACKGROUND END
    {
      type: 'line',
      yref: 'paper',
      x0: 5,
      y0: 0,
      x1: 5,
      y1: 1,
      line: {
        color: 'black',
        width: 2,
        dash: 'solid'
      }
    },
    {
      type: 'line',
      //@ts-ignore
      xref: 'paper',
      x0: 0,
      y0: 5,
      x1: 1,
      y1: 5,
      line: {
        color: 'black',
        width: 2,
        dash: 'solid'
      }
    }
  ];

  const annotations: any[] = [
    {
      yref: 'y',
      x: 0,
      xanchor: 'left',
      y: 10,
      yanchor: 'top',
      text: 'GREAT',
      font: {
        size: 20,
        color: 'black',
        weight: '600'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 0,
      xanchor: 'left',
      y: 5,
      yanchor: 'bottom',
      text: 'What are we waiting for? Let\'s go for it!',
      font: {
        size: 14,
        color: 'onyx',
        weight: '400'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 5,
      xanchor: 'left',
      y: 10,
      yanchor: 'top',
      text: 'GOOD',
      font: {
        size: 20,
        color: 'black',
        weight: '600'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 5,
      xanchor: 'left',
      y: 5,
      yanchor: 'bottom',
      text: 'Can we decrease risks with quick experiments?',
      font: {
        size: 14,
        color: 'onyx',
        weight: '400'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 0,
      xanchor: 'left',
      y: 5,
      yanchor: 'top',
      text: 'WEAK',
      font: {
        size: 20,
        color: 'black',
        weight: '600'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 0,
      xanchor: 'left',
      y: 0,
      yanchor: 'bottom',
      text: 'Can we increase the impact?',
      font: {
        size: 14,
        color: 'onyx',
        weight: '400'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'y',
      x: 5,
      xanchor: 'left',
      y: 5,
      yanchor: 'top',
      text: 'Maybe later',
      font: {
        size: 20,
        color: 'black',
        weight: '600'
      },
      opacity: 0.8,
      showarrow: false
    },
    {
      yref: 'paper',
      xref: 'paper',
      x: 1,
      xanchor: 'right',
      y: -0.1,
      yanchor: 'bottom',
      text: 'Bubble size represents initiative budget amount',
      font: {
        color: 'onyx',
        weight: '400'
      },
      opacity: 0.8,
      showarrow: false
    }
  ];

  return {
    'shapes': shapes,
    'annotations': annotations
  };
}

function calculatePData(businessData: BusinessExtended[], seriesProperty: string): Plotly.Data[] {

  const dataByBusinessId = groupByReduce(businessData, 'businessId');

  //@ts-ignore
  var latestBusinessData = flattenGroupBy(dataByBusinessId, arr => findMin(arr, timeUntil(new Date())));
  var latestBusinessDataBySeries = groupByReduce(latestBusinessData, seriesProperty);
  var seriesNames: string[] = Object.keys(latestBusinessDataBySeries);

  // Find maximum budget, used to for scaling bubble sizes
  const maxBudget = latestBusinessData?.reduce((max, item) => Math.max(budgetToNumber(item?.businessPotential), max), 0);

  return seriesNames?.map(name => createSeriesData(
    name,
    latestBusinessDataBySeries[name],
    maxBudget,
    SET_COLORS[seriesNames.indexOf(name)]
  ));

}
