import { FC } from 'react';
import {
    Create,
    CreateProps, SaveButton, Toolbar, useNotify, useRedirect,
} from 'react-admin';
import { Plan } from '../../model/ScaleTypes';
import { useLocation } from 'react-router-dom';
import { PlanForm, transformPlan } from './PlanForm';
import { CancelButton } from '../../components/BackButton';

export const PlanCreate: FC<CreateProps> = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const businessId = queryParams.get('businessId');

    const transform = (data: any) => ({
        ...transformPlan(data),
        businessId
    })

    const onSuccess = (data: Plan) => {
        notify(`generic.created`, { type: "success", messageArgs: { itemType: "Milestone Plan" } });
        redirect('show', '/api_business', data.businessId)
    }

    const onError = (error: any) => {
        notify(`Could not save edit: ${error.message}`)
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }} transform={transform}>
            <PlanForm toolbar={<Toolbar>
                <SaveButton type="button" />
                <CancelButton /></Toolbar>} />
        </Create>
    );
};

export default PlanCreate;


