import { Button, styled } from '@mui/material'

interface FileInputProps {
  accept: string
  onFileSelect: (f?: File) => unknown
  text?: string
}

const makeStyledInput = styled('input')

const HiddenInput = makeStyledInput({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const FileInput = ({
  accept,
  text = 'Select file',
  onFileSelect,
}: FileInputProps) => (
  <Button
    component='label'
    role={undefined}
    size='small'
    tabIndex={-1}
    variant='contained'
  >
    <span>{text}</span>
    <HiddenInput
      type='file'
      accept={accept}
      multiple={false}
      onChange={(e) => onFileSelect(e.target.files?.item(0) ?? undefined)}
      />
  </Button>
)

export default FileInput
