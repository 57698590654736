import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  percentage: {
    fontWeight: 'bold',
  },
}));

interface ChangeIndicatorProps {
  change: number;
  changeMode?: 'relative' | 'absolute';
  alignItems?: BoxProps['alignItems'];
  justifyContent?: BoxProps['justifyContent'];
}

export const ChangeIndicator: React.FC<ChangeIndicatorProps> = ({
  change,
  changeMode = 'relative',
  alignItems = 'center',
  justifyContent = 'center'
}) => {
  const { classes } = useStyles();
  const color = change > 0 ? 'green' : change < 0 ? 'red' : 'gray';
  let icon = change > 0 ? <ArrowUpwardIcon style={{ color: 'green' }} /> : change < 0 ? <ArrowDownwardIcon style={{ color: 'red' }} /> : <></>;

  const string = change === 0 ? '-' :
    changeMode === 'relative' ?
      Math.abs(change).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }) :
      Math.abs(change).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 });

  return (
    <Box
      display="flex"
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      {icon}
      <span className={classes.percentage} style={{ color }}>
        {string}
      </span>
    </Box>
  );
};
