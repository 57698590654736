import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Typography, IconButton, Collapse, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { MaturityScore, PriorityListItem } from '../model/ScaleTypes';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HistoryNoteCard } from './cards/HistoryNoteCard';


export interface AutomaticPriorityListProps {
  /**
   * Show on the priorityList only the caregories 
   */
  filterNonEvaluated: boolean,
  /**
   * Maximum number of results to show.
   */
  maxResults: number,
  /**
   * Type of the chart, default bar
   */
  automaticPriorityList: PriorityListItem[],
  scores: MaturityScore[],
  onClickPriorityItem?: (clickedPriorityItem: any) => void;
}

const useStyles = makeStyles()((theme: any) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  }
}));

/**
 * Primary UI component for user interaction
 */
export const AutomaticPriorityList: React.FC<AutomaticPriorityListProps> = ({
  filterNonEvaluated = false,
  maxResults,
  automaticPriorityList,
  scores = [],
  onClickPriorityItem,
  ...props
}) => {

  const [planningOpen, setOpen] = React.useState(false);
  const [objective, setObjective] = React.useState<any>();

  const [notes, setNotes] = React.useState<string>();
  const handleOpen = (_item: any, _notes: string) => {
    setObjective(_item);
    setNotes(_notes);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const { classes } = useStyles();

  const priorityItems = filterNonEvaluated ?
    automaticPriorityList?.filter(item => scores.find(score => score.maturityCategoryId === item.categoryId)) :
    automaticPriorityList;

  const PriorityRow: React.FC<{ item: PriorityListItem, index: number }> = ({ item, index, ...props }) => {
    const [open, setOpen] = React.useState(false);

    const note = scores.find(score => score.maturityCategoryId === item.categoryId);

    return (
      <React.Fragment key={index} {...props}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {note &&
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
          </TableCell>
          <TableCell align={'center'}><strong>{index + 1}</strong></TableCell>
          <TableCell style={{ border: 0 }}><Typography>{item.objective}</Typography></TableCell>
          <TableCell style={{ border: 0 }}><Typography noWrap>{item.levelName} ({item.levelNumber})</Typography></TableCell>
          <TableCell style={{ border: 0 }}><Typography>{item.categoryName}</Typography></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ backgroundColor: '#f4f4f4', paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {note && 
              <Box style={{ padding: 4}}><HistoryNoteCard note={note} /></Box>}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }


  return <TableContainer id="priorityList" classes={classes} component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell/>
          <TableCell><strong>Priority</strong></TableCell>
          <TableCell><strong>Objective</strong></TableCell>
          <TableCell><strong>Level</strong></TableCell>
          <TableCell><strong>Category</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {priorityItems?.map((item, index) => <PriorityRow key={index} item={item} index={index} />).filter((v, i) => i < maxResults)}
      </TableBody>
    </Table>
  </TableContainer>
};