import React, { CSSProperties, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MaturityCategory, MaturityDescriptionExtended, MaturityLevel, MaturityScore } from '../../model/ScaleTypes';
import Joyride, { Step } from 'react-joyride';
import { MATURITY_CATEGORY_COLORS, MATURITY_CATEGORY_COLORS_RESOLVED } from '../Colors';
import { MaturityDescriptionState, MaturityDescriptionTableCell } from './MaturityDescriptionTableCell';
import { CategoryTitleCell } from './CategoryTitleCell';
import { Typography } from '@mui/material';
import { maturityTableTutorialSteps } from './MaturityTableTutorial';
import { ScoresByCategories } from '../../pages/MaturityView';
import { useSearchParams } from 'react-router-dom';

const verticalCellStyle: CSSProperties = {
  writingMode: 'vertical-rl',
  transform: 'rotate(180deg)',
  transformOrigin: 'center center',
  whiteSpace: 'nowrap',
  padding: '4px',
  fontWeight: 'bold'
}

export interface MaturityTableProps {
  categories: MaturityCategory[];
  levels: MaturityLevel[];
  descriptions: MaturityDescriptionExtended[];
  modelData: ScoresByCategories;
  updatedAt: number;
  hiddenLevels?: number[];
  onClickBack?: () => void;
  onClickExport?: () => void;
  onClickDescriptionCheckbox?: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  freemium?: boolean;
}

function levelStatus(categoryScore: MaturityScore, level: number): MaturityDescriptionState {
  if (!categoryScore) return 'unchecked';
  if (categoryScore.notesScore === level) {
    if (categoryScore.notesScore === categoryScore.score) return 'checked'; // means last note was resolved
    return 'inprogress'; // note was for different level than the score (ie. comment on the next level)
  }
  if (categoryScore.score >= level) return 'checked';
  return "unchecked";
}

export const MaturityTable: React.FC<MaturityTableProps> = ({
  categories = [],
  levels = [],
  descriptions = [],
  modelData = {},
  updatedAt,
  freemium = false,
  hiddenLevels = [],
  onClickBack = () => { },
  onClickExport = () => { },
  onClickDescriptionCheckbox = (clickedCategoryDescription: MaturityDescriptionExtended) => { },
}) => {

  const [transpose, setTranspose] = React.useState<boolean>(false)
  const [run, setOnboardingRun] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([]);

  const [collapsedLevels, setCollapsedLevels] = React.useState<number[]>(hiddenLevels);
  const [collapsedCategories, setCollapsedCategories] = React.useState(new Set<number>([]));

  // This manipulates which categories are shown (by ordinal). If empty, all are shown.
  const [focusCategoryOrdinals, setFocusCategoryOrdinals] = React.useState(new Set<number>([]));
  const [resetCounter, setResetCounter] = React.useState<number>(0);
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setCollapsedLevels(hiddenLevels);
  }, [hiddenLevels]);

  useEffect(() => {
    if (searchParams && searchParams.has('focusCategories')) {
      const categoryOrdinals = searchParams.get('focusCategories')?.split(',').map(n => parseInt(n, 10)) ?? []
      console.log('Parsed category ordinals:', categoryOrdinals, 'from', searchParams.get('focusCategories'))
      setFocusCategoryOrdinals(new Set(categoryOrdinals))
    }
  }, [searchParams])

  const handleReset = () => {
    setResetCounter(prevCounter => prevCounter + 1);
  };

  // hide categories that have all shown levels checked
  useEffect(() => {
    const shownLevels = levels.filter(level => collapsedLevels.indexOf(level.level) === -1);
    const highestShownLevel = Math.max(...shownLevels.map(lvl => lvl.level));
    const collapsedCategories = new Set<number>();
    categories.forEach(category => {
      // if the category's level is higher or equal to highest then it is hidden.
      if (highestShownLevel <= modelData[category.id]?.score) collapsedCategories.add(category.order);
    });
    setCollapsedCategories(collapsedCategories);
  }, [collapsedLevels, levels, categories, modelData, resetCounter]);

  useEffect(() => {
    setSteps(maturityTableTutorialSteps);
  }, []);

  const handleToggleHiddenLevels = () => {
    if (collapsedLevels.length > 0) setCollapsedLevels([]);
    else setCollapsedLevels(hiddenLevels);
  };

  const toggleCategory = (category: MaturityCategory) => setCollapsedCategories(prevSet => {
    const set = new Set(prevSet);
    set.delete(category.order)
    return set;
  })

  document.title = "Scale SaaS - Maturity Assessment";

  const matrix: {
    [maturityCategoryId: string]: { [maturityLevelId: string]: MaturityDescriptionExtended }
  } = {};
  descriptions.forEach(desc => matrix[desc.maturityCategoryId] = {});
  descriptions.forEach(desc => matrix[desc.maturityCategoryId][desc.maturityLevelId] = desc);

  const shownLevels = useMemo(() => levels.filter(level => !collapsedLevels.includes(level.level)), [collapsedLevels, levels]);
  const shownCategories = useMemo(() => categories.filter(category => !collapsedCategories.has(category.order)), [collapsedCategories, categories]);

  const ToggleLevelsButtonHorizontal = () => <Button size="small" variant="text" style={{ width: '100%' }} color="primary" onClick={handleToggleHiddenLevels}>
    {collapsedLevels.length > 0 ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>

  const ToggleLevelsRow = ({ rowKey }: { rowKey: string }) => hiddenLevels.length > 0 ?
    <TableRow key={rowKey}>
      <TableCell colSpan={categories.length * 2 + 2} align="center">
        <ToggleLevelsButtonHorizontal />
      </TableCell>
    </TableRow> : <></>

  const ToggleLevelsButtonVertical = () => <Button size='small' style={{ minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
    color="primary" onClick={handleToggleHiddenLevels}>
    {collapsedLevels.length > 0 ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>

  const ActionButtonsCell = () => (
    <TableCell align="center">
      <Button variant="outlined" style={{ width: '100px' }} onClick={onClickBack}>Back</Button>
      <Button variant="outlined" style={{ width: '100px' }} onClick={onClickExport}>Export</Button>
    </TableCell>
  )

  const categoriesInTable = focusCategoryOrdinals.size > 0 ?
    categories.filter(category => focusCategoryOrdinals.has(category.order)) :
    categories;

  const NormalTable = () =>
    <Table size="small">
      <TableHead>
        <TableRow className="categories">
          <ActionButtonsCell />
          {categoriesInTable.map((category, index) => <CategoryTitleCell key={index} colSpan={2} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <ToggleLevelsRow rowKey="toggleAbove" />
        {shownLevels.map((level, rowIndex) =>
          <TableRow key={rowIndex}>
            <TableCell component="th" scope="row" className={"levelName_" + level.level} >{level.level} {level.name}</TableCell>

            {categoriesInTable.map((category, i) => {
              const isShown = shownCategories.some(cat => cat.id === category.id);
              if (isShown) return <MaturityDescriptionTableCell key={i}
                maturityDescription={matrix[category.id][level.id]}
                state={levelStatus(modelData[category.id], level.level)}
                onClickDescriptionCheckbox={onClickDescriptionCheckbox}
                updatedAt={updatedAt}
                freemium={freemium}
              />

              if (rowIndex > 0) return null; // only first is drawn, is it has colspan
              // The vertical table cell to show the category that has been collapsed.
              return <TableCell key={i} colSpan={2} rowSpan={shownLevels.length}
                style={{
                  ...verticalCellStyle,
                  backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                  color: 'white'
                }}
                align="center">
                <Button size='small' style={{ color: 'white', minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
                  onClick={() => setCollapsedCategories(prevSet => {
                    const set = new Set(prevSet);
                    set.delete(category.order)
                    return set;
                  })}
                >{category.name} ★ RESOLVED</Button>
              </TableCell>
            }
            )}

            <TableCell>{level.level} {level.name}</TableCell>
          </TableRow>
        )}
        <ToggleLevelsRow rowKey="toggleBelow" />
        {/* This is the bottom row */}
        <TableRow>
          <ActionButtonsCell />
          {categoriesInTable.map((category, index) => <CategoryTitleCell colSpan={2} key={index} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableBody>
    </Table >

  const reversedShownLevels = shownLevels.slice().reverse();

  const TransposedTable = () => <Table size="small">
    <TableHead></TableHead>
    <TableBody>
      <TableRow className="levels">
        <ActionButtonsCell />
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        {reversedShownLevels.map((level, levelIndex) =>
          <TableCell key={"levels" + levelIndex} colSpan={2} width="200px" align="center" className={"levelName_" + level.level}>
            <Typography minWidth={200}>{level.level} {level.name}</Typography>
          </TableCell>
        )}
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        <ActionButtonsCell />
      </TableRow>
      {categoriesInTable.map((category, categoryIndex) => {
        const isShown = shownCategories.some(cat => cat.id === category.id);

        if (!isShown) {
          return <TableRow key={categoryIndex}>

            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>
            <TableCell colSpan={reversedShownLevels.length * 2}
              style={{
                backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                color: 'white'
              }}
              align="center">
              <Button size='small' style={{ color: 'white', minHeight: 0, width: "100%", padding: '0px', margin: '0px' }} variant='text'
                onClick={() => toggleCategory(category)}
              >{category.name} ★ RESOLVED</Button>
            </TableCell>
            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>

          </TableRow>
        }
        return <TableRow key={categoryIndex}>
          <CategoryTitleCell key={"first" + categoryIndex} category={category} skipped={false} />
          {reversedShownLevels.map((level, colIndex) =>
            <MaturityDescriptionTableCell key={colIndex}
              maturityDescription={matrix[category.id][level.id]}
              state={levelStatus(modelData[category.id], level.level)}
              onClickDescriptionCheckbox={onClickDescriptionCheckbox}
              updatedAt={updatedAt}
              freemium={freemium}
            />
          )}
          <CategoryTitleCell key={"second" + categoryIndex} category={category} skipped={false} />
        </TableRow>
      }
      )}

      {/* This is the bottom row */}
      <TableRow key="bottomRow">
        <ActionButtonsCell />
        {reversedShownLevels.map((level, levelIndex) =>
          <TableCell key={"bottom" + levelIndex} colSpan={2} width="500px" align='center' className={"levelName_" + level.level}>{level.level} {level.name}</TableCell>
        )}
        <ActionButtonsCell />
      </TableRow>

    </TableBody>
  </Table>

  return <TableContainer className="maturityTableCont" id="maturityTable" component={Paper}>
    <Joyride
      continuous={true}
      run={run}
      scrollToFirstStep={true}
      showSkipButton={false}
      steps={steps}
      styles={{
        overlay: {
          width: document.body.scrollWidth
        },
        options: {
          zIndex: 10000,
        },
      }}
    />
    <Button onClick={() => { setCollapsedLevels([]); setOnboardingRun(!run); }}>Tutorial</Button>
    <Button onClick={() => { setTranspose(!transpose) }}>Transpose</Button>
    <Button onClick={handleReset}>Collapse</Button>
    {transpose ? <TransposedTable /> : <NormalTable />}
  </TableContainer>
};

