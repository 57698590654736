import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from './state/login/LoginReducer';
import { Main } from './Main';
import { Typography } from "@mui/material";

import { LoadingAnimation } from "./components/LoadingAnimation";

const REFRESH_TOKEN_BUFFER_MS = 60 * 60 * 1000;

function getJwtExpiry(token: string) {
  try {
    const base64Url = token.split('.')[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert Base64-URL to Base64
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const payload = JSON.parse(jsonPayload);
    return payload.exp ? payload.exp * 1000 : null; // Convert to milliseconds
  } catch (e) {
    console.error('Error decoding JWT', e);
    return null;
  }
}

export function isTokenExpired(token: string) {
  const expiryTime = getJwtExpiry(token);
  if (expiryTime === null) return true;

  const timeToExpiration = expiryTime - Date.now();
  console.log("Time to expiry ", timeToExpiration)

  return expiryTime && (Date.now() + REFRESH_TOKEN_BUFFER_MS > expiryTime);
}

export function MainWithAuth() {
  const dispatch = useDispatch()
  const { isLoading, error, getIdTokenClaims, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [isTokenRefreshing, setIsTokenRefreshing] = useState<Boolean>(false);
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  if (inviteMatches && orgMatches) {
    loginWithRedirect({
      organization: orgMatches[1],
      invitation: inviteMatches[1],
    });
  }

  const refreshAccessToken = async () => {
    setIsTokenRefreshing(true);
    try {
      console.log('Attempting to refresh token');
      await getAccessTokenSilently();
      const token = await getIdTokenClaims();
      localStorage.setItem('scale_token', token?.__raw ?? "")
      dispatch(setLoggedIn(token?.__raw));
    } catch (error) {
      console.error('Error refreshing token', error);
      loginWithRedirect();
    }
    setIsTokenRefreshing(false);
  };

  // Focus listener to check token validity when focus returns to app.
  useEffect(() => {
    const checkValidity = async () => {
      const token = localStorage.getItem('scale_token');
      console.log("Checkin token validity.");
      if (token && isTokenExpired(token)) {
        await refreshAccessToken();
      } 
    };
    window.addEventListener('focus', checkValidity);
    window.addEventListener('mousedown', checkValidity);
    return () => {
      window.removeEventListener('focus', checkValidity);
      window.removeEventListener('mousedown', checkValidity);
    }
  }, [refreshAccessToken, isTokenExpired]);


  useEffect(() => {
    const init = async () => {
      if (isAuthenticated) {
        await refreshAccessToken();
      } else if (!isLoading) {
        console.log('Not authenticated. Redirecting to login.');
        localStorage.removeItem('scale_token');
        dispatch(setLoggedIn(undefined));
        loginWithRedirect();
      }
    };
    init();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  if (isTokenRefreshing) return <LoadingAnimation loadingText="Refreshing authentication" />
  if (isLoading) return <LoadingAnimation loadingText="Loading authentication" />
  if (!isAuthenticated) return <LoadingAnimation loadingText="Login (not authenticated yet)" />

  if (error) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography>Please refresh the browser, application state needs to be reloaded.</Typography></div>
    )
  }
  return <Main />
}

