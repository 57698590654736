import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    card: {}
});

const TermsView = () => {
    const { classes } = useStyles();
    return (
        <Card className={classes.card}>
            <CardHeader title="Terms and Conditions" />
            <CardContent>
                <div>You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these <a href="https://app.scale-company.com/termsandconditions.html" rel="noreferrer" target="_blank">Terms and Conditions</a> and our <a href="https://scale-company.com/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>.</div>
            </CardContent>
            <CardContent>
                <div>
                    <div><strong>Terms and Conditions:</strong> <a href="https://app.scale-company.com/termsandconditions.html" rel="noreferrer" target="_blank">https://app.scale-company.com/termsandconditions.html</a></div>
                    <div><strong>Privacy Policy:</strong> <a href="https://scale-company.com/privacy-policy" rel="noreferrer" target="_blank">https://scale-company.com/privacy-policy</a></div>
                </div>
            </CardContent>
        </Card>
    );
};



export default TermsView;