import {
  Alert,
  Button as MaterialUiButton,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Button } from "react-admin"
import FileList from "./files/FileList"
import FileUploadDialog from "./files/FileUploadDialog"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import { BusinessExtended, ProjectFile } from "../../model/ScaleTypes"
import { useScaleDataProvider } from "../../state/provider/ScaleDataProvider"

type ProjectFilesCardProps = {
  project: BusinessExtended
}

const ProjectFilesCard = ({ project }: ProjectFilesCardProps) => {
  const dataProvider = useScaleDataProvider()

  const [shouldLoadFiles, setShouldLoadFiles] = useState<boolean>(true)

  const [isLoadingFiles, setLoadingFiles] = useState<boolean>(true)
  const [fileLoadError, setFileLoadError] = useState<Error>()
  const [fileLoadRetryCount, setFileLoadRetryCount] = useState<number>(0)
  const [files, setFiles] = useState<ProjectFile[]>()

  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [isUploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const [uploadedFileName, setUploadedFileName] = useState<string>()
  const [fileUploadError, setFileUploadError] = useState<Error>()

  const reloadFileList = () => {
    if (fileLoadRetryCount < 3) {
      setFileLoadRetryCount(fileLoadRetryCount + 1)
      setFiles(undefined)
      setLoadingFiles(false)
      setFileLoadError(undefined)
      setShouldLoadFiles(true)
    }
  }

  const uploadSelectedFile = async (file: File) => {
    setFileUploadError(undefined)
    setUploadedFileName(file.name)
    setUploadInProgress(true)
    setShowUploadDialog(false)
    try {
      await dataProvider.uploadFile({ projectId: project.id, file })
      setUploadInProgress(false)
      setShouldLoadFiles(true)
    } catch (err) {
      setUploadInProgress(false)
      setUploadedFileName(undefined)
      setFileUploadError(err as Error)
    }
  }

  useEffect(() => {
    if (project.id && dataProvider && shouldLoadFiles) {
      (async () => {
        setLoadingFiles(true)
        try {
          const files = await dataProvider.listProjectFiles({ projectId: project.id })
          setFiles(files)
          setLoadingFiles(false)
          setFileLoadError(undefined)
        } catch (err) {
          setFiles(undefined)
          setLoadingFiles(false)
          setFileLoadError(err as Error)
        } finally {
          setShouldLoadFiles(false)
        }
      })()
    }
  }, [project, dataProvider, shouldLoadFiles])

  return (
    <Card>
      <CardHeader title='Project Attachments' />
      <CardContent>
        <Button
          disabled={false}
          label='Add Image'
          variant='contained'
          style={{ marginBottom: '8px' }}
          onClick={() => setShowUploadDialog(true)}
        />
        { uploadedFileName && !isUploadInProgress &&
          <Alert severity='success' variant='outlined' onClose={() => setUploadedFileName(undefined)}>
            <span><i>{uploadedFileName}</i> has been added</span>
          </Alert>
        }

        <FileUploadDialog
          title={`Add image to ${project.name}`}
          visible={showUploadDialog}
          onCancel={() => setShowUploadDialog(false)}
          onUpload={uploadSelectedFile}
        />

        { isUploadInProgress && uploadedFileName &&
            <LoadingIndicator loadingText={<span>Uploading <i>{uploadedFileName}</i>...</span>} />
        }
        { fileUploadError && !isUploadInProgress &&
            <Alert severity='warning'>
              <span>Failed to upload <i>{uploadedFileName}</i>, please try again</span>
            </Alert>
        }
        { isLoadingFiles &&
            <LoadingIndicator loadingText={ uploadedFileName ? 'Refreshing files...' : 'Loading files...' } />
        }
        { fileLoadError &&
            <Alert
              severity='warning'
              action={
                fileLoadRetryCount < 3 &&
                  <MaterialUiButton color='inherit' size='small' variant='outlined' onClick={reloadFileList}>RELOAD</MaterialUiButton>
              }
              >
                <span>
                  Loading files failed
                  { fileLoadRetryCount < 3 && ', please try again' }
                </span>
            </Alert>
        }
        { files && isLoadingFiles === false &&
          <div style={isUploadInProgress ? { opacity: 0.5, filter: 'grayscale(50%)'} : {}}>
            { files.length === 0 &&
              <Alert severity='info'>No files have been uploaded</Alert>
            }
            { files.length > 0 &&
              <FileList files={files} />
            }
          </div>
        }
      </CardContent>
    </Card>
  )
}

export default ProjectFilesCard
