import { DataProvider, UpdateResult, DeleteResult, CreateResult } from 'react-admin';
import { MaturityScore } from './ScaleTypes';

type SuccessHandler = (updated: MaturityScore) => void;
type FailHandler = () => void;

const strategies = {
  create: async (
    dataProvider: DataProvider,
    note: Partial<MaturityScore>,
  ): Promise<CreateResult<MaturityScore>> =>
    await dataProvider.create<MaturityScore>(
      'scale_maturityScores',
      {
        data: note
      }
    ),
  update: async (
    dataProvider: DataProvider,
    note: Partial<MaturityScore>
  ): Promise<UpdateResult<MaturityScore>> =>
    await dataProvider.update<MaturityScore>(
      'scale_maturityScores',
      {
        id: note.id,
        data: { id: note.id, notes: note.notes },
        previousData: { id: note.id }
      }
    ),
  delete: async (
    dataProvider: DataProvider,
    note: Partial<MaturityScore>
  ): Promise<DeleteResult<MaturityScore>> =>
    await dataProvider.delete<MaturityScore>(
      'scale_maturityScores',
      {
        id: note.id || 'no_id'
      }
    )
};

export const buildMaturityNoteOperation = (
  operationType: keyof typeof strategies,
  dataProvider: DataProvider,
  success: SuccessHandler = () => { },
  fail: FailHandler = () => { }
) => {
  return async (note: Partial<MaturityScore>) => {
    try {
      const result = await strategies[operationType](dataProvider, note);
      success(result.data);
    } catch {
      fail();
    }
  };
};