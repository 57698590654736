import gql from 'graphql-tag'
import {
  BuildFields,
  buildFields,
  FetchType
} from 'ra-data-hasura'

const EXTENDED_MATURITY_MODEL = gql(`
{
  phases {
    id
    lastLevel
    firstLevel
    name
    areaColor
    areaTitle
  }
  milestones {
    id
    shortName
    name
    description
    level
    color
    line
    lineText
  }
  categories {
    category {
      id
      order
      name
    }
  }
  levels {
    level {
      id
      level
      name
    }
    score
  }
  levels_aggregate {
    aggregate { max {score} }
  }
}
`)

const PLAN_FIELDS = `{
  id
  name
  businessId
  startDate
  targetDate
  startLevel
  targetLevel
  targetDate
  startSelection
  targetSelection
  description
}`

const EXTENDED_BUSINESS = gql(`
{
  insights {
    id
    risk
    impact
  }
  organizationUnit {
    name
    organization {
      id
      friendlyId
    }
  }
  plans ${PLAN_FIELDS}
}
`)

const extractFieldsFromQuery = (ast: any) => ast.definitions[0].selectionSet.selections
const isGet = (x?: FetchType) => x && [FetchType.GET_LIST, FetchType.GET_MANY, FetchType.GET_ONE].includes(x)

const extendedMaturityModelFields = extractFieldsFromQuery(EXTENDED_MATURITY_MODEL)
const extendedBusinessFields = extractFieldsFromQuery(EXTENDED_BUSINESS)

export const customBuildFields: BuildFields = (type: any, fetchType?: FetchType) => {
  const resourceName = type.name
  const defaultFields = buildFields(type, fetchType)
  const isGetOp = isGet(fetchType)

  if (resourceName === 'api_maturityModelByOrganization' && isGetOp) {
    defaultFields.push(...extendedMaturityModelFields)
  }

  else if ((resourceName === 'api_business' || resourceName === 'api_history_business')
           && isGetOp) {
    defaultFields.push(...extendedBusinessFields)
  }

  // Extend other queries for other resources/fetchTypes here...

  return defaultFields
}
