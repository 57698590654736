import { Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

interface InfoIconTooltipProps {
  title: string | React.ReactNode;
}

const InfoIconTooltip = (props: InfoIconTooltipProps) => {
  return <Tooltip title={<Typography>{props.title}</Typography>
  } placement="bottom-end" arrow>
    <InfoIcon style={{ transform: "scale(0.7)", color: "grey" }} fontSize="small" />
  </Tooltip>
}

export default InfoIconTooltip;
