import { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { MaturityDescription, MaturityDescriptionExtended, MaturityModel, MaturityScore, MaturityScoreExtended, constructMaturityModelFromRecord } from '../model/ScaleTypes';

export default function useMaturityModel() {
  const [maturityModel, setMaturityModel] = useState<MaturityModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { data, total, isLoading } = useGetList<MaturityModel>(
    'api_maturityModelByOrganization',
    { pagination: { page: 1, perPage: 10000 }, },
    { staleTime: 2 * 60 * 1000 }
  );

  useEffect(() => {
    if (!isLoading) {
      if (data && total === 1) {
        setMaturityModel(constructMaturityModelFromRecord(data[0]));
      } else {
        console.error(`Failure loading maturity model, received ${total} models.`, data)
        setError(true);
      }
      setLoading(false);
    }
  }, [total, data, isLoading]);
  return { maturityModel, loading, error };
}

export function extendMaturityDescription(description: MaturityDescription, maturityModel: MaturityModel): MaturityDescriptionExtended {
  const level = maturityModel.levels.find(lvl => lvl.id === description.maturityLevelId);
  const category = maturityModel.categories.find(category => category.id === description.maturityCategoryId);
  // @ts-ignore TODO 
  return { ...description, level, category }
}

export function extendMaturityScore(score: MaturityScore, maturityModel: MaturityModel): MaturityScoreExtended {
  return {
    ...score,
    level: maturityModel.levels.find(lvl => lvl.level === score.notesScore),
    category: maturityModel.categories.find(category => category.id === score.maturityCategoryId),
  }
}


/*
export function extendMaturityScore(score: MaturityScore, maturityModel: MaturityModel): MaturityScoreExtended {
  const level = maturityModel.levels.find(lvl => lvl.level.level === score.score)?.level;
  const category = maturityModel.categories.find(category => category.category.id === score.maturityCategoryId)?.category;
  // @ts-ignore TODO 
  return { ...score, level, category }
}
*/