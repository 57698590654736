import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    iframe: {
        border: 'none',
        width: 'auto',
        height: '100%'
    },
});

const ModifySubscriptionView = () => {
    const { classes } = useStyles();
    return (
        <iframe className={classes.iframe} scrolling="no" src="https://scale-company-test.chargebeeportal.com/portal/login" title="Scale SaaS subscription management"></iframe>
    );
};



export default ModifySubscriptionView;