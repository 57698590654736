import { ReactNode, useState } from "react";
import { SettingsContext, SettingsContextType, defaultSettings } from "./SettingsContext";

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }): JSX.Element => {
  const [interfaceSettings, setInterfaceSettings] = useState(defaultSettings.interfaceSettings);
  const [dataProviderUpdated, setDataProviderUpdated] = useState(0);

  const setInterfaceSetting = (key: keyof SettingsContextType['interfaceSettings'], value: any) => {
    setInterfaceSettings(prevState => ({
      ...prevState,
      [key]: value,
    }));
    console.log("interface", key, value, interfaceSettings);
  };
  
  return (
    <SettingsContext.Provider value={{ interfaceSettings, dataProviderUpdated, setInterfaceSetting, setDataProviderUpdated }}>
      {children}
    </SettingsContext.Provider>
  );
};