import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { createRoot } from 'react-dom/client';

const tagManagerArgs = {
    gtmId: 'GTM-K2N75HH',
    dataLayer: {}
}

if ( window.location.hostname === 'localhost' ) {

  const developer = { dataLayer: { 'traffic_type' : 'developer' }};
  TagManager.dataLayer(developer);
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root');
createRoot(rootElement!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
