import { useGetOne } from 'react-admin';
import { BusinessExtended } from '../model/ScaleTypes';

export default function useBusiness(businessId: string): { business: BusinessExtended | null, isLoading: boolean } {
  const { data, isLoading } = useGetOne<BusinessExtended>('api_business', { id: businessId })
  return {
    business: data ?? null,
    isLoading,
  }
}
