import { ThemeProvider } from '@mui/material';
import React from 'react';
import { LoadingAnimationProps, LoadingIndicator } from './LoadingIndicator'
import { theme } from '../layout/theme';

export type { LoadingAnimationProps } from './LoadingIndicator'

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ loadingText }) => (
  <ThemeProvider theme={theme}>
    <div style={{height: '100%'}}>
      <div style={{margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)'}}>
        <LoadingIndicator loadingText={loadingText} />
      </div>
    </div>
  </ThemeProvider>
)
