import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface ComparisonPeriodDropdownProps {
  period: number,
  setPeriod: (value: number) => void 
}

export function ComparisonPeriodDropdown(props: ComparisonPeriodDropdownProps) {

  const handleChange = ((event: SelectChangeEvent<any>, child: React.ReactNode) => {
    props.setPeriod(event.target.value);
  });

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }} variant='outlined'>
      <InputLabel id="demo-select-small-label">Comparison</InputLabel>
               <Select
          value={props.period}
          label="Comparison"
          defaultValue={6}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Select Period</MenuItem>
          <MenuItem value={1}>1 Month Ago</MenuItem>
          <MenuItem value={3}>3 Months Ago</MenuItem>
          <MenuItem value={6}>6 Months Ago</MenuItem>
          <MenuItem value={12}>1 Year Ago</MenuItem>
          <MenuItem value={24}>2 Years Ago</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}