import { BusinessHistory, MaturityScore } from './../model/ScaleTypes';
import { useGetList } from "react-admin";

export function useBusinessHistoryWithScore(businessId: string | undefined | null) {
    const { data, isLoading, error } = useGetList(
        'api_businessHistoryWithScore',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'modifiedAt', order: 'ASC' },
            filter: { businessId }
        });
    return { data: data as BusinessHistory[] || [], isLoading, error };
}


export function useMaturityScoreData(businessId: string | undefined | null) {
    const { data, isLoading, error, refetch } = useGetList(
        "scale_maturityScores",
        // CREATED AT TELLS THE TIME WHEN THE LAST SCORE UPDATE WAS
        { pagination: { page: 1, perPage: 1000 }, sort: { field: "createdAt", order: "DESC" }, filter: { businessId } }
    );
    return { data: data as MaturityScore[] || [], isLoading, error, refetch };

    //    if (loading || isLoading) return { data: [] as MaturityScoreExtended[], isLoading: isLoading || loading, error, refetch };
    //    if (!maturityModel) return { data: [] as MaturityScoreExtended[], isLoading: false, error: new Error("Failed loading maturity Model"), refetch };
    //    return { data: (data as MaturityScore[]).map(score => extendMaturityScore(score, maturityModel)) || [], isLoading, error, refetch };
}
