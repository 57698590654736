import Tooltip, { TooltipProps } from "@mui/material/Tooltip"

/**
 * A Material UI Tooltip which is rendered even if its first child is disabled,
 * as per https://mui.com/material-ui/react-tooltip/#disabled-elements
 */
const ButtonTooltip = (props: TooltipProps): JSX.Element => (
  <Tooltip {...props}>
    <div>
      {props.children}
    </div>
  </Tooltip>
)

export default ButtonTooltip
