import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import React, { useContext } from 'react';
import { useState } from 'react';
import { PortfolioBubbleChart } from '../components/charts/PortfolioBubbleChart';
import { ProjectsInPhases } from '../components/ProjectsInPhases';
//import TagManager from 'react-gtm-module';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useRedirect, useTranslate } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { SettingsContext } from '../state/SettingsContext';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import useMaturityModel from '../state/useMaturityModel';
import { BusinessExtended, BusinessHistory } from '../model/ScaleTypes';
import { FullScreenButton } from '../components/FullScreenButton';
//import { theme } from './theme';

const StatusScreen = (props: any) => {

  document.title = "Scale SaaS - Dashboard"

  const redirect = useRedirect();
  const settings = useContext(SettingsContext);
  const { maturityModel, loading: mmLoading } = useMaturityModel();

  const [bubbleColorProperty, setBubbleColorProperty] = useState<keyof BusinessHistory>("name");

  const translate = useTranslate();

  const { data, isLoading: loading } = useGetList(
    'api_businessHistoryWithScore', {
    pagination: { page: 1, perPage: 10000 },
    filter: {
      "deleted@_neq": true
    }
  },
  );//, { field: "scoreModifiedAt", order: 'desc'});

  const { permissions, isLoading: permissionsLoading } = useScalePermissions();

  if (loading || mmLoading || permissionsLoading) {
    return <LoadingAnimation loadingText='Loading portfolio data' />
  }

  if (!maturityModel) return <p>Error: failed loading maturity model.</p>
  const dataInArray: BusinessHistory[] = data?.filter(business => business.status !== 'removed') as BusinessHistory[];

  var onClick = function (series: any, dataPoint: BusinessExtended) {
    if (dataPoint == null) return;
    var closestID = dataPoint.businessId;
    redirect("show", "api_business", closestID);
    return;
  };

  //@ts-ignore
  return (
    <div className="scaleDashboard" style={{ backgroundColor: 'white', padding: '10px' }}>
      <div className="dashboardContainer">
        <Grid container>
          {
            dataInArray.length === 0 ?
              <Grid item sm={12}>
                <Card>
                  <Grid container direction='row' justifyContent='center'>
                    <Grid item sm={5}>
                      <Typography variant="h6">Glad to see you here{permissions.user?.given_name ? " " + permissions.user?.given_name : ""}!</Typography>
                      <Typography variant="body1"><p>You have no projects in your portfolio yet. Start exploring by creating your first project!</p></Typography>
                      <Grid container direction='row-reverse' justifyContent='space-evenly'>
                        <Grid item><Button variant="contained" color='primary' onClick={() => redirect("scale_business/create")}>Create project</Button></Grid>
                        <Grid item>
                          <span className="toggleDemoData">
                            <Typography>Show demo data: <Switch
                              checked={settings.interfaceSettings.demoMode}
                              onChange={() => settings.setInterfaceSetting('demoMode', !settings.interfaceSettings.demoMode)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            </Typography>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={2}>
                      <img alt="Welcome! Time to start exploring." src="https://storage.googleapis.com/scale_bucket-1/undraw_adventure_map_hnin.svg" width={200} />
                    </Grid>
                  </Grid>
                </Card>
              </Grid> : ""}
          <Grid item xs={12} sm={12} md={12}>
            <Card className="bubbleChart" >
              <CardHeader title="Portfolio View" action={<InfoIconTooltip title={translate('pos.definitions.portfolioBubbleChart')} />} />
              {/* <CardWithIco to="/" icon={DollarIcon} title="hello" value={total} /> */}
              <CardContent><Typography>Total of {dataInArray.length} Cases in the portfolio.</Typography></CardContent>
              <PortfolioBubbleChart maturityModel={maturityModel} seriesProperty={bubbleColorProperty} /*compareMonthsAgo={6}*/ data={dataInArray} onClickDataPoint={onClick} />
              <CardContent>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Visualisation</FormLabel>
                  <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={bubbleColorProperty} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBubbleColorProperty((event.target as HTMLInputElement).value as keyof BusinessHistory);
                  }}>
                    <FormControlLabel value="name" control={<Radio color='primary' />} label="Business" />
                    <FormControlLabel value="organizationUnitName" control={<Radio color='primary' />} label="Organization" />
                    <FormControlLabel value="status" control={<Radio color='primary' />} label="Status" />
                    <FormControlLabel value="horizon" control={<Radio color='primary' />} label="Horizon" />
                    <FormControlLabel value="businessModel" control={<Radio color='primary' />} label="Business model" />
                  </RadioGroup>
                </FormControl>
              </CardContent>

            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card className="pipeline">
              <CardHeader title="Projects in pipeline" action={<InfoIconTooltip title={translate('pos.definitions.pipelineProjects')} />} />
              <ProjectsInPhases maturityModel={maturityModel} maxItemsToList={0} data={dataInArray}></ProjectsInPhases>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} sm={4} md={4}>
            <FullScreenButton />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withIdentityChecks(StatusScreen);
