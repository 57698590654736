import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material"
import { ProjectFile } from "../../../model/ScaleTypes"
import { clampNumBetween, isNil, isNotNil } from "../../../util/ScaleUtils"

const FileList = ({files}: {files: ProjectFile[]}) => (
  <ImageList
    cols={clampNumBetween(files.length, 3, 5)}
    gap={8}
    variant='quilted'
    >
    { files.map(file => (
        <ImageListItem key={file.url}>
          <img src={file.url} alt={file.name} />
          <ImageListItemBar title={file.name} position='top' />
        </ImageListItem>
      ))
    }
  </ImageList>
)

export default FileList
