import Card from '@mui/material/Card';
import { BusinessExtended } from '../model/ScaleTypes';
import { BusinessList } from '../components/BusinessList';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useRedirect, useTranslate } from 'react-admin';
import { Alert, Button, CardContent, CardHeader, Grid } from '@mui/material';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import React, { useEffect, useState } from 'react';
import useMaturityModel from '../state/useMaturityModel';
import { canCreateReport, handleDownloadReport } from './CustomReportPage';
import { useScaleDataProvider } from '../state/provider/ScaleDataProvider';
import { isNotNil } from '../util/ScaleUtils';

const BusinessListView = (props: any) => {

    document.title = "Scale SaaS - Business List"
    const { permissions, isLoading } = useScalePermissions();

    const { data, isLoading: loading } = useGetList(
        'api_business', {
        pagination: { page: 1, perPage: 100 },
        filter: {
            "deleted@_neq": true,
            "businessId@_in": permissions.isLimitedAccess ? permissions.allowedBusinessIds : undefined
        }
    });
    //React.useEffect(() => { if ( identity === undefined || identity['email_verified'] === false ) {redirect("/")}; }, [loading, identity] );

    if (loading || isLoading) {
        return <LoadingAnimation loadingText='Loading data' />
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard data={data?.filter(business => business.status !== 'removed') as BusinessExtended[]} />
            </Grid>
        </Grid>
    );
}

export const BusinessListCard: React.FC<{ data: BusinessExtended[], initialNumberOfRows?: number }> = ({
    data,
    initialNumberOfRows = 25
}) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const dataProvider = useScaleDataProvider();
    const { maturityModel, loading: mmLoading } = useMaturityModel();
    const { permissions, isLoading } = useScalePermissions();
    const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([]); // State to track selected businesses
    const [isReportPossible, setIsReportPossible] = useState<boolean | undefined>(undefined);

    // This checks if the report creation is possible for the business
    useEffect(() => {
        if (isReportPossible === undefined && isNotNil(data) && isNotNil(data[0])) {
            canCreateReport(data[0]).then(setIsReportPossible)
        }
    }, [data]);

    if (mmLoading || isLoading || !data) return <LoadingAnimation loadingText='Loading data' />
    if (!maturityModel) return <Alert severity="error">Maturity model is not available, please contact the support via the bottom right corner.</Alert>

    return <Card>
        <CardHeader title={translate('business.list.title')} action={
            <>
                <Button style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                    variant="contained"
                    color='primary'
                    disabled={selectedBusinesses.length === 0 || !isReportPossible}
                    onClick={() => {
                        // Implement your print logic here, using selectedBusinesses
                        const businesses: BusinessExtended[] = [];
                        selectedBusinesses.forEach(businessId => {
                            const business = data.find(business => business.id === businessId);
                            if (business) {
                                businesses.push(business);
                            }

                        });
                        if (businesses.length === 0) return;
                        handleDownloadReport(dataProvider, businesses, maturityModel);
                    }}>
                    {translate('business.createReport' + (isReportPossible ? '' : '_template_missing'))}
                </Button>
                {!permissions.isLimitedAccess && data.length > 0 &&
                    <Button style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                        variant="contained"
                        color='primary'
                        onClick={() => redirect("/scale_business/create")} >{translate('business.create')}</Button>}
            </>
        } />
        <CardContent>
            <BusinessList maturityModel={maturityModel}
                initialNumberOfRows={initialNumberOfRows}
                businessList={data}
                onCheckboxChange={(id, checked) => {
                    if (checked) {
                        setSelectedBusinesses([...selectedBusinesses, id]);
                    } else {
                        setSelectedBusinesses(selectedBusinesses.filter(businessId => businessId !== id));
                    }
                }}
                onClickListItem={(item) => redirect("show", "api_business", item.businessId)}
            />
        </CardContent>
    </Card>
}

export default withIdentityChecks(BusinessListView);
