import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Create, required, SimpleForm, TextInput, ReferenceInput, SelectInput, List, Datagrid, TextField, ReferenceField, DateField, Edit, EditButton, Toolbar, SaveButton } from 'react-admin';


export const OrganizationList = () => (
    <List>
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <TextField label="Friendly ID" source="friendlyId" />
            <ReferenceField label="Maturity Model" source="maturityModelId" reference="scale_maturityModel">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" />
            <DateField source="modifiedAt" />
            <EditButton />
        </Datagrid>
    </List>
);

const OrganizationForm = (props: any) => (
    <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>}>
        <Typography>Customer organization</Typography>
        <TextInput source="name" validate={required()} />
        <Tooltip title="FriendlyId is an unique identifier for the organizational used for access control" placement="right" arrow>
            <div><TextInput disabled={props.edit} source="friendlyId" validate={required()} /></div>
        </Tooltip>
        <ReferenceInput source="maturityModelId" reference="scale_maturityModel" validate={required()}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="maturityModelContextId" reference="scale_maturityModelContext" validate={required()}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>
)


export function OrganizationCreate() {
    return <Create><OrganizationForm /></Create>
}

export function OrganizationEdit() {
    return <Edit><OrganizationForm edit={true} /></Edit>
}