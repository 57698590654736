import { ReactNode, createContext, useContext, useState } from 'react';

export interface SettingsContextType {
  interfaceSettings: {
    demoMode: boolean;
    fullScreen: boolean;
  }
  setInterfaceSetting: (key: keyof SettingsContextType['interfaceSettings'], value: any) => void;
  dataProviderUpdated: number;
  setDataProviderUpdated: (value: number) => void;
}

export const defaultSettings: SettingsContextType = {
  interfaceSettings: {
    demoMode: false,
    fullScreen: false,
  },
  setInterfaceSetting: (key: keyof SettingsContextType['interfaceSettings'], value: any) => { 
    throw new Error("Should have been replaced...");
  },
  dataProviderUpdated: 0,
  setDataProviderUpdated: (value: number) => { }
};

export const SettingsContext = createContext<SettingsContextType>(defaultSettings);

export const useSettings = () => useContext(SettingsContext);


