import { Layout, LayoutClasses, LayoutProps, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import ScaleMenu from './Menu';
import { useSettings } from '../state/SettingsContext';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const ScaleLayout = (props: LayoutProps) => {
    const settings = useSettings()

    if (settings.interfaceSettings.fullScreen) return (
      <div className={LayoutClasses.appFrame}>
        <main className={LayoutClasses.contentWithSidebar}>
            <div id="main-content" className={LayoutClasses.content}>{props.children}</div>
        </main>
      </div>
    )

    return (
      <Layout
        {...props}
        appBar={AppBar}
        sidebar={CustomSidebar}
        menu={ScaleMenu}
      />
    )
}

export default ScaleLayout;