import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles';

const extendMuiButton = styled(MuiButton)

export const ScaleButton = extendMuiButton({
  borderRadius: '14px',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

const extendScaleButton = styled(ScaleButton)

export const CallToActionButton = extendScaleButton({
  alignSelf: 'flex-end',
  backgroundColor: '#FF5F6D',
  borderRadius: '22px',
  fontSize: '16px',
})
