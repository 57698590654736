import { Typography } from '@mui/material'
import React from 'react'
import { Rings } from  'react-loader-spinner'
import { scaleColors } from '../layout/theme'

export interface LoadingAnimationProps {
  loadingText?: string | JSX.Element
}

export const LoadingIndicator: React.FC<LoadingAnimationProps> = ({ loadingText = 'Loading' }) => <>
  <Rings
    height="80"
    width="80"
    color={scaleColors.Brand}
    radius="6"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="rings-loading"/>
  <Typography>{loadingText}</Typography>
</>
