import React from "react";
import { Datagrid, EditButton, Filter, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput } from 'react-admin';
import { JsonField } from "react-admin-json-view";

const QuickFilter = (props: any) => (
        <Filter {...props}>
                <TextInput label="Name" source="name" />
                <TextInput label="Description" source="description" />
                <ReferenceInput source="maturityCategoryId" reference="scale_maturityCategory" sort={{ field: "order", order: "ASC" }}>
                        <SelectInput optionText="order" />
                </ReferenceInput>
                <ReferenceInput source="maturityLevelId" reference="scale_maturityLevel" sort={{ field: "level", order: "ASC" }}>
                        <SelectInput optionText="level" />
                </ReferenceInput>
        </Filter>
);


export const MaturityDescriptionList = (props: any) => (
        <List {...props}
                filters={<QuickFilter />}
                bulkActionButtons={false}
                sort={{ field: "maturityCategory.order", order: "ASC" }} >

                <Datagrid>
                        <TextField source="name" />
                        <TextField source="description" />
                        <ReferenceField label="Category" source="maturityCategoryId" reference="scale_maturityCategory">
                                <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Category Order" source="maturityCategoryId" reference="scale_maturityCategory">
                                <TextField source="order" />
                        </ReferenceField>
                        <ReferenceField label="Level" source="maturityLevelId" reference="scale_maturityLevel">
                                <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Level #" source="maturityLevelId" reference="scale_maturityLevel">
                                <TextField source="level" />
                        </ReferenceField>
                        <JsonField
                                source="tools"
                                jsonString={false} // Set to true if the value is a string, default: false
                                reactJsonOptions={{
                                        // Props passed to react-json-view
                                        name: null,
                                        collapsed: false,
                                        enableClipboard: false,
                                        displayDataTypes: false,
                                }}
                        />


                        <EditButton />
                </Datagrid>
        </List>
);
