import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { BusinessExtended, BusinessHistory } from '../model/ScaleTypes';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useRedirect } from 'react-admin';
import { Alert, Grid } from '@mui/material';
import useMaturityModel from '../state/useMaturityModel';
import { PortfolioMilestoneChart } from '../components/charts/PortfolioMilestoneChart';
import { TimelineChart } from '../components/charts/TimelineChart';

export default function SteeringReportView() {
  const redirect = useRedirect();
  const { maturityModel, loading: mmLoading } = useMaturityModel();

  const { data, isLoading: loading } = useGetList(
    'api_business',
    { pagination: { page: 1, perPage: 10000 } }
  );

  if (loading || mmLoading) return <LoadingAnimation />
  if (!maturityModel) return <Alert severity="error">Maturity model is not available, please contact the support via the bottom right corner.</Alert>

  const dataInArray: BusinessHistory[] = data!.filter(data => data.status === 'active' || data.status === null);

  var onClick = function (series: any, dataPoint: BusinessExtended) {
    if (dataPoint == null) return;
    var closestID = dataPoint.id;
    redirect("show", "api_business", closestID);
    return;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} >
        <Card>
          <CardHeader title="Project Portfolio" />
          <CardContent>
            <PortfolioMilestoneChart maturityModel={maturityModel} height="500px" onClickDataPoint={onClick} data={dataInArray} levelRange={[0, 8]} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} >
        <Card >
          <CardHeader title="Project Timelines" />
          <CardContent>
            <TimelineChart maturityModel={maturityModel} data={dataInArray} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

