import Typography from "@mui/material/Typography";
import React, { Fragment, ReactElement, useContext, useEffect, useState } from "react";
import { AutocompleteArrayInput, Button, DateInput, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput, Validator, email, required, useGetList, useRecordContext, useTranslate } from "react-admin";
import { useFormContext } from 'react-hook-form';
import { BusinessExtended } from "../../model/ScaleTypes";
import { TrelloIcon } from "../../integrations/trello/trelloApi";
import { TrelloBoardSelectInput, TrelloListSelectInput } from "../../integrations/trello/TrelloComponents";
import TrelloApiContext from "../../integrations/trello/trelloContext";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { Grid } from "@mui/material";
import { isEmpty } from "../../util/ScaleUtils";
import currency from "currency.js";
import { CurrencyFormat } from "../../configuration/configuration";

export const REMOVE_BUSINESS_CASE_ATTRIBUTE = 'removeBusinessCase';

type FieldComponentType = () => ReactElement;
function useOptionalField(recordName: string, fieldLabel: string, FieldComponent: FieldComponentType) {
  const translate = useTranslate();
  const record = useRecordContext();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const field = record[fieldLabel]
    setIsVisible(!isEmpty(field));
  }, [record])
  const toggleVisibility = () => setIsVisible(!isVisible);

  const ToggleButton = () => (
    <Button size='small' style={{ fontSize: 8 }} variant='text' onClick={toggleVisibility} label={`${isVisible ? '-' : '+'} ${translate(recordName + "." + fieldLabel)}`} />
  );
  const Content = () => { return isVisible ? <FieldComponent /> : <Fragment /> };
  return [ToggleButton, Content];
}


export const BusinessForm = ({ ...props }) => {
  const [showBusinessCase, setShowBusinessCase] = React.useState(true);
  const [trelloIsAvailable, setTrelloIsAvailable] = React.useState(false);
  const [boardId, setBoardId] = React.useState<string>('');
  const [choices, setChoices] = React.useState<{ id: string, name: string }[]>([]);
  const { data: tags, isLoading } = useGetList("api_tagList");

  const translate = useTranslate();
  const trello = useContext(TrelloApiContext)!;

  const [ToggleProblemDefinition, ProblemDefinitionField] = useOptionalField("business", "problemDescription", () => <Grid item xs={12} sm={12} md={12}>
    <TextInput label="business.problemDescription" source="problemDescription" multiline fullWidth rows={4} />
  </Grid>)
  const [ToggleBusinessBenefits, BusinessBenefitsField] = useOptionalField("business", "businessBenefits", () => <Grid item xs={12} sm={12} md={12}>
    <TextInput label="business.businessBenefits" source="businessBenefits" multiline fullWidth rows={4} />
  </Grid>)
  const [ToggleInvolved, InvolvedField] = useOptionalField("business", "stakeholders", () => <Grid item xs={12} sm={12} md={12}>
    <TextInput label="business.stakeholders" source="stakeholders" multiline fullWidth rows={4} />
  </Grid>)

  useEffect(() => {
    if (!tags) return;
    const tagNames = Array.from(new Set(tags.map(tag => tag.name))).sort();
    setChoices(tagNames.map(tag => ({ id: tag, name: tag })));
  }, [tags]);

  useEffect(() => {
    trello.isWorking().then(value => setTrelloIsAvailable(value));
  }, [trello]);

  const handleBoardChange = (newBoardId: string) => {
    setBoardId(newBoardId);
  }

  if (isLoading) return <LoadingAnimation />

  function checkErrors(
    record: any, // Object containing all form values
    fieldName: string, // The name of the field to validate
    errors: any, // The existing errors object to accumulate new errors
    ...validators: Validator[]
  ): void {
    validators.forEach(validator => {
      //@ts-ignore
      const error = validator(record[fieldName]);
      if (error) {
        if (!errors[fieldName]) {
          errors[fieldName] = error;
        }
      }
    });
  }
  const validateBusinessEdit = (record: any) => {
    const errors: any = {};
    record = record as BusinessExtended;
    console.log("Validate", record);

    if (showBusinessCase) {
      if (!record.businessPotential || Number(record.businessId) === 0) errors["businessPotential"] = 'errors.empty';
      if (!record.budget || Number(record.budget) === 0) errors["budget"] = 'errors.empty';
    }
    if (record.trelloBoardId) {
      if (!record.trelloListId || record.trelloListId === '') errors['trelloListId'] = 'List needs to be selected too';
    }

    checkErrors(record, 'name', errors, required())
    checkErrors(record, 'startDate', errors, required());
    checkErrors(record, 'organizationUnitId', errors, required());
    checkErrors(record, 'status', errors, required());
    return errors;
  }

  // Button to toggle the business case and set the record to dirty.
  const BusinessCaseToggle = () => {
    const form = useFormContext();

    const toggleBusinessCase = () => {
      if (showBusinessCase && form) {
        // resets the business potential info, this was the only way I was able to make it so that the dirty state was 
        // also reset after the removed toggle was done, combined with the validate business case 
        form.setValue('businessPotential', null, { shouldDirty: true });
        form.setValue('budget', null, { shouldDirty: true });
      }
      setShowBusinessCase(!showBusinessCase);
    };

    return (
      <Button variant="contained" color="primary" onClick={toggleBusinessCase}
        label={showBusinessCase ? 'Remove Business Case' : 'Add Business Case'} />
    );
  };


  return <TabbedForm validate={validateBusinessEdit} {...props}>
    <FormTab label="generic.edit">

      <Typography variant="h5" gutterBottom>Project Information</Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={9}>
          <TextInput autoFocus source="name" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <ReferenceInput label="business.status" source="status" reference="scale_status">
            <SelectInput validate={required()} optionText="id" />
          </ReferenceInput>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ReferenceInput source="organizationUnitId" reference="scale_organizationUnit">
            <SelectInput validate={required()} fullWidth optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="leadPersonEmail" type="email" fullWidth validate={[email()]} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput label="business.description" source="description" multiline fullWidth rows={4} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ToggleProblemDefinition />
          <ToggleBusinessBenefits />
          <ToggleInvolved />
        </Grid>

        <ProblemDefinitionField />
        <BusinessBenefitsField />
        <InvolvedField />

        <Grid item xs={12} sm={12} md={3}>
          <DateInput source="startDate" defaultValue={new Date()} validate={required()} fullWidth />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <DateInput source="estimatedLaunchDate" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} />
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <SelectInput fullWidth source="horizon" choices={[
            { id: '1', name: 'Horizon 1: ' + translate('pos.definitions.h1definition') },
            { id: '2', name: 'Horizon 2: ' + translate('pos.definitions.h2definition') },
            { id: '3', name: 'Horizon 3: ' + translate('pos.definitions.h3definition') },
          ]} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput fullWidth source="businessModel" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>

          <AutocompleteArrayInput
            label="business.tags"
            source="tags"
            onCreate={(val) => {
              if (!val) return;
              const newOption = { id: val, name: val };
              setChoices(currentChoices => [...currentChoices, newOption]);
              return newOption;
            }}
            choices={choices}
            optionText="name"
            optionValue="id"
            freeSolo
          />
        </Grid>
      </Grid>


      <Typography variant="h5" gutterBottom>Business Case Information</Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <BusinessCaseToggle />
        </Grid>
        {showBusinessCase && <>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              format={val => currency(val, CurrencyFormat).format()}
              parse={val => currency(val, CurrencyFormat).value}
              fullWidth source="businessPotential" hidden={!showBusinessCase} disabled={!showBusinessCase} validate={required()} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              format={val => currency(val, CurrencyFormat).format()}
              parse={val => currency(val, CurrencyFormat).value}
              fullWidth source="budget" hidden={!showBusinessCase} disabled={!showBusinessCase} validate={required()} />
          </Grid>
          {/* <NumberInput source="totalAddessableMarket" step={10000} hidden={noBusinessCase} disabled={noBusinessCase} validate={[number(), minValue(0)]} />
    <NumberInput source="targetMarket" step={10000} hidden={noBusinessCase} disabled={noBusinessCase} validate={[number(), minValue(0)]} /> */}
        </>}
      </Grid>
    </FormTab>

    {
      trelloIsAvailable && <FormTab label={<div style={{ display: 'flex', alignItems: 'center' }}><TrelloIcon />&nbsp;Trello</div>}>
        <TrelloBoardSelectInput source="trelloBoardId" handleChange={handleBoardChange} />
        <TrelloListSelectInput source="trelloListId" boardSource="trelloBoardId" boardId={boardId} />
      </FormTab>
    }

    {
      !trelloIsAvailable && <FormTab label={<div style={{ display: 'flex', alignItems: 'center' }}><TrelloIcon />&nbsp;Trello</div>}>
        <Button variant="contained" onClick={() => { window.open("/integrations") }} label='Setup Trello integration' />
      </FormTab>
    }



  </TabbedForm >

};

