import React, { Fragment } from 'react';
import { HasScore, MaturityModel, TimeStamped } from '../model/ScaleTypes';
import { useTranslate } from 'react-admin';
import { Alert, Box, Typography } from '@mui/material';
import { ChangeIndicator } from './common/ChangeIndicator';
import dayjs from 'dayjs';
import { maturityScoreColorScale } from './Colors';

export interface MaturityScoreCardProps {
  data: (HasScore & TimeStamped)[];
  maturityModel?: MaturityModel;
  onClick?: () => void;
}

export const BOX_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  p: 2,
  gap: 1,
  bgcolor: '#FFFFFF',
  boxShadow: 1,
  borderRadius: 1,
}

function getLevelName({ maturityModel, maturityScore }: { maturityModel?: MaturityModel, maturityScore: number }): string {
  // TODO: Fix null pointer bug that occurs if current user doesn't have access to particular business case. How to reproduce: open project, change organisation from Auth0, reload page. Level is undefined and throws error.
  return maturityModel?.levels
    ? maturityModel?.levels.filter((level) => {
      return level.level === Math.floor(Math.min(maturityModel?.maxLevel - 1, maturityScore)) + 1
    })[0].name
    : "";
}

export const MaturityScoreCardContent: React.FC<MaturityScoreCardProps> = ({
  maturityModel,
  data,
  onClick = () => { }
}) => {
  if (data.length === 0) return <Fragment />
  if (!maturityModel) return <Alert severity='error'>No maturity model.</Alert>
  const current = data[data.length - 1];
  const previous = data.length >= 2 ? data[data.length - 2] : undefined;

  const timeDiffInDays = Math.abs(dayjs(current.modifiedAt).diff(dayjs(previous?.modifiedAt || dayjs()), 'day'))
  const scoreDiff = current.score - (previous?.score || 0)
  const rateOfchange = scoreDiff / (timeDiffInDays / 30) * current.score;

  const color = maturityScoreColorScale(current.score / maturityModel.maxLevel);
  const levelName = getLevelName({ maturityModel, maturityScore: current.score })

  return <Fragment>
    <Box sx={{ cursor: 'pointer', }} onClick={onClick}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ p: 2, borderRadius: 1 }} bgcolor={color}>
          <Typography variant="h2" lineHeight='48px' fontSize={'64px'} fontWeight={700}>{current.score.toFixed(1)}</Typography>
        </Box>

        <Box>
          <Typography variant="body1" fontWeight={700}>{levelName}</Typography>
          {previous &&
            <Fragment>
              <Typography variant="caption" color="textSecondary">change since {dayjs(previous.modifiedAt).fromNow()}</Typography>
              <ChangeIndicator justifyContent={'left'} change={current.score - previous.score} changeMode='absolute' />
            </Fragment>
          }
        </Box>
      </Box>

      <Typography variant="body2" color="textSecondary">
        This is the average score based on your progress and previous maturity assessment on a scale of 0 - 10.
      </Typography>
    </Box>
  </Fragment >
}

export const MaturityScoreCard: React.FC<MaturityScoreCardProps> = ({
  data,
  maturityModel,
  onClick
}) => {
  const translate = useTranslate();
  if (!maturityModel) return <Alert severity='error'>{translate('errors.maturity_model_missing')}</Alert>
  const opacity = data.length === 0 ? 0.5 : 1;

  return <Box
    sx={{ ...BOX_STYLE, opacity }}>
    <Typography variant="h5" gutterBottom fontWeight={700}>Maturity Score</Typography>
    <MaturityScoreCardContent data={data} maturityModel={maturityModel} onClick={onClick} />
  </Box >
};
