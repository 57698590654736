import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import config from "../configuration/authConfig";

const AdminSettings = (props: any) => {

  const { permissions } = useScalePermissions();

  if (!permissions.isAdmin) {
    return <p>Unauthorized</p>
  }

  const configKeys = Object.keys(config);

  const envKeys = Object.keys(process.env);
  return (
    <>
      <p>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {configKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  { /** @ts-ignore */}
                  <TableCell>{config[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </p>
      <p>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {envKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  { /** @ts-ignore */}
                  <TableCell>{process.env[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </p>
    </>
  );
}

export default withIdentityChecks(AdminSettings);
