import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { ScaleStatus } from '../../model/ScaleTypes'

function statusToColor(status: ScaleStatus | undefined): string {
  switch (status) {
    case 'active':
      return '#3BE256'
    
    case 'removed':
      return 'red'
    
    case 'on hold':
      return 'lightgray'
    
    case 'not tracked':
      return 'black'
  }

  return '#000000'
}

const ProjectStatusChip = ({ status }: { status?: ScaleStatus }) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <FiberManualRecordIcon fontSize="inherit" style={{ color: statusToColor(status), marginRight: 4 }} />
    <span style={{ textTransform: 'capitalize' }}>{ status }</span>
  </span>
)

export default ProjectStatusChip
