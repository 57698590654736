import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    token: undefined as string | undefined,
    organization: undefined as string|undefined
  },
  reducers: {
    setLoggedIn(state, { payload: token }: PayloadAction<string | undefined>) {
      state.token = token
    },
    setOrganization(state, { payload: organization }: PayloadAction<string|undefined>) {
      state.organization = organization
    }
  }
})

export const {
  setLoggedIn,
  setOrganization
} = loginSlice.actions