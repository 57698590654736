import { Button } from "react-admin";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";

export const BackButton = ({ label = 'Back', ...props }) => {
  const navigate = useNavigate();
  const handleCancel = () => { navigate(-1); };
  return <Button onClick={handleCancel} label={label} {...props} />
};

export const CancelButton = (props: JSX.IntrinsicAttributes & { [x: string]: any; label?: string | undefined; }) => <BackButton label="Cancel" {...props} />;
