import React, { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RedoIcon from '@mui/icons-material/Redo';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Tooltip from '@mui/material/Tooltip';
import { MaturityDescriptionExtended } from "../../model/ScaleTypes";
import { toParagraphs } from "../../util/GuiUtils";
import { IN_PROGRESS_BAR, IN_PROGRESS_CELL, INVISIBLE, RESOLVED_BRIGHT, RESOLVED_STANDARD } from '../Colors';

export type MaturityDescriptionState = 'checked' | 'inprogress' | 'unchecked' | 'disabled';

const stateConfig: Record<MaturityDescriptionState, { sideBackground: string; mainBackground: string; Icon?: React.ElementType, iconColor: string }> = {
  checked: {
    sideBackground: RESOLVED_STANDARD,
    mainBackground: RESOLVED_BRIGHT,
    Icon: CheckCircleIcon,
    iconColor: 'black',
  },
  inprogress: {
    sideBackground: IN_PROGRESS_BAR,
    mainBackground: IN_PROGRESS_CELL,
    Icon: RedoIcon,
    iconColor: 'black',
  },
  unchecked: {
    sideBackground: '#f4f4f4',
    mainBackground: INVISIBLE,
    Icon: CheckBoxOutlineBlankIcon,
    iconColor: INVISIBLE,
  },
  disabled: {
    sideBackground: '#f4f4f4',
    mainBackground: INVISIBLE,
    Icon: CheckBoxOutlineBlankIcon,
    iconColor: INVISIBLE,
  },
};



export interface MaturityDesciptionCellProps {
  key: React.Key;
  maturityDescription: MaturityDescriptionExtended;
  state: MaturityDescriptionState;
  onClickDescriptionCheckbox: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  freemium: boolean;
  updatedAt: number;
}

export const MaturityDescriptionTableCell: React.FC<MaturityDesciptionCellProps> = ({ updatedAt, freemium, maturityDescription, state, onClickDescriptionCheckbox }) => {
  const { category, level } = maturityDescription;
  const isFreemiumDisabled = freemium && (level.level > 3 || category.order > 5);
  const finalState = isFreemiumDisabled ? 'disabled' : state;

  const config = stateConfig[finalState];
  const isDisabled = (state === 'disabled');

  const toolTipDescription = isFreemiumDisabled ? "FREEMIUM INCLUDES FIRST 3 LEVELS & 5 CATEGORIES" :
    toParagraphs(
      maturityDescription.description +
      (maturityDescription.examples?.length > 0 ? "\nExamples\n" + maturityDescription.examples : ""));

  const handleClick = () => !isDisabled && onClickDescriptionCheckbox(maturityDescription);
  return (<>
    { /** checkbox part */}
    <TableCell onClick={handleClick} style={{ backgroundColor: config.sideBackground, padding: '0px', cursor: !isDisabled ? 'pointer' : 'default' }}>
      {config.Icon && <config.Icon fontSize="small" style={{ color: config.iconColor, margin: '5px', borderRadius: '50%' }} />}
    </TableCell>
    { /** content part. className needed for Joyride */}
    <TableCell
      onClick={handleClick}
      className={
        category.name === 'Financial Model'
          ? `fin_${level.level}`
          : category.name === 'Team Composition'
            ? `team_${level.level}`
            : ''
      }
      style={{ backgroundColor: config.mainBackground, cursor: 'pointer' }}>
      <p style={{ fontSize: '0.85em' }}>
        {maturityDescription.name}
        {maturityDescription.description?.length > 0 && (
          <Tooltip title={toolTipDescription} arrow>
            <InfoIcon
              style={{
                transform: 'scale(0.5)',
                padding: -3,
                margin: -3,
                verticalAlign: 'bottom',
                color: 'orange',
              }}
              fontSize="small"
            />
          </Tooltip>
        )}
      </p>
    </TableCell>
  </>)

}