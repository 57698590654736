import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

const SelectedImage = ({ file } : {file: File}) => (
  <img
    alt={file.name}
    src={URL.createObjectURL(file)}
    style={{
      maxWidth: '100%',
      maxHeight: '100%' }
    }
  />
)

const UploadIcon = () => (
  <FileUploadOutlinedIcon
    fontSize='large'
    style={{
      width: '100%',
      height: '100%',
      opacity: '0.4',
      filter: 'grayscale(0.8)'
    }}
  />
)

const FilePreview = ({ file } : {file?: File}): JSX.Element => (
  <div style={{ width: '100px', height: '150px' }}>
    { file
        ? <SelectedImage file={file}/>
        : <UploadIcon />
    }
  </div>
)

export default FilePreview
