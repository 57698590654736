import { Card, CardContent, Alert, CardActions, CardHeader, Chip, Typography } from '@mui/material';
import { Business, MaturityCategory, MaturityLevel, MaturityMilestone, MaturityModel, MaturityScoreOnly, Plan } from '../../model/ScaleTypes';
import { Button, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import SortableTable, { Column } from '../common/SortableTable';
import useMaturityModel from '../../state/useMaturityModel';
import { LoadingAnimation } from '../LoadingAnimation';
import useBusinessHistoryScores from '../../state/useBusinessHistoryScores';
import { groupByReduce } from '../../util/ScaleUtils';
import dayjs from '../../configuration/configuredDayjs';
import { TAG_STYLE } from '../../pages/ProjectDashboard';
import { maturityScoreColorScale } from '../Colors';


export interface PlanCardProps {
  business: Business,
  plans?: Plan[], // Array of plans
}

export function createDisplayNameFromPlanSelectionValue(selectionValue: string, maturityModel: MaturityModel): MaturityLevel | MaturityMilestone | null {
  const delimPosition = selectionValue.indexOf('-');
  const value = Number(selectionValue.substring(0, delimPosition));
  const type = selectionValue.substring(delimPosition + 1)

  var selectionObject: MaturityLevel | MaturityMilestone | undefined;
  switch (type) {
    case 'milestone':
      selectionObject = maturityModel?.milestones?.find(milestone => milestone.level === value);
      break;
    case 'level':
      selectionObject = maturityModel?.levels?.find(level => level.level === value);
      break;
  }
  if (selectionObject) return selectionObject;
  return null;
}

const columnStyle = {
  maxWidth: "180px",
  minWidth: "50px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const rowFormatter = (item: Plan): React.CSSProperties => {
  if (dayjs().isAfter(item.targetDate)) return {
    backgroundColor: "whitesmoke",
    fontStyle: "italic",
    padding: "1px"
  }
  return {
    padding: "1px"
  }
}

type MilestoneTableProps = {
  maturityModel: MaturityModel,
  maturityScoreHistory: MaturityScoreOnly[],
  plans?: Plan[],
  onEditClick?: (plan: Plan) => void
}

export const MilestoneTable = ({
  maturityModel,
  maturityScoreHistory,
  plans,
  onEditClick = undefined,
}: MilestoneTableProps) => {
  const navigate = useNavigate()

  const lookupById = groupByReduce(maturityScoreHistory, 'maturityCategoryId')

  // this is a kind of a hack, trying to somehow pass what to do with edit button press in.
  function openPlan(plan: Plan) { navigate(`/scale_plan/${plan.id}`) }
  onEditClick = onEditClick || openPlan;
  if (!onEditClick) return <></>;

  // @TODO sort the data!
  const categories: MaturityCategory[] = maturityModel.categories
    .filter(cat => lookupById[cat.id] && lookupById[cat.id].find(val => val.score));
  //  if (categories.length === 0) return <Alert severity="warning">{translate('errors.assessment_missing')}</Alert>;
  const categoryScores: (number | undefined)[] = categories.map(cat => lookupById[cat.id] ? lookupById[cat.id][0]?.score : undefined);
  const columns: Column<Plan>[] = [
    {
      id: 'name',
      header: 'Name',
      style: columnStyle,
      format: (_, item) => (
        <Typography fontWeight={700}>{item.name}</Typography>
      )
    },
    {
      id: 'targetDate',
      header: 'Target date',
      style: columnStyle
    },
    {
      id: 'targetSelection',
      header: 'Target', style: columnStyle,
      format: (value, plan) => {
        const item = createDisplayNameFromPlanSelectionValue(value, maturityModel);
        if (!item) return <></>
        return <Chip sx={{ ...TAG_STYLE, bgcolor: maturityScoreColorScale(item.level / maturityModel.maxLevel) }} label={item.name} />
      }
    },
    {
      id: 'businessId',
      header: 'Progress',
      style: columnStyle,
      format: (_, plan) => {
        if (plan.targetLevel === null) return "N/A";
        if (dayjs().isBefore(plan.startDate)) return `Not started`;// in ${-dayjs().diff(dayjs(plan.startDate), "month")}`
        const startLevel: number = plan.startLevel || 0;
        const levelDiff = plan.targetLevel - startLevel;
        const totalLevelsToComplete = (categoryScores.length * levelDiff);

        var levelsCompleted = 0;
        // the levels which are even below the start level are considered to be at start level.
        // the levels which are above the target level are considered to be at the target level.
        categoryScores.forEach(currentScore => {
          levelsCompleted += Math.min(Math.max(currentScore || 0, startLevel), plan.targetLevel || 0) - startLevel
        });
        return levelsCompleted + "/" + totalLevelsToComplete + (levelsCompleted > 0 && levelsCompleted === totalLevelsToComplete ? " 🎉" : "");
      }
    },
    {
      id: 'id',
      header: '',
      style: columnStyle,
      format: (_, plan) => (
        <Button
          label="actions.generic_edit"
          type="submit"
          color="primary"
          variant='outlined'
          disabled={!onEditClick}
          onClick={() => onEditClick && onEditClick(plan)}
        />
      )
    }
  ];

  return plans
    ? <SortableTable
      columns={columns}
      data={plans}
      defaultSortColumn='targetDate'
      rowFormatter={rowFormatter}
      initialRowsPerPage={5}
    />
    : null

  {/*
        <List>
          {plans?.map((plan, index) => (<>
            <ListItem key={plan.id}>
              <ListItemText
                primary={plan.name ? plan.name : plan.targetMilestone ? plan.targetMilestone.name : plan.targetDate}
                secondary={plan.name || plan.targetMilestone ? <>Target date: {plan.targetDate}</> : plan.startDate}
              />
              <Button label="actions.generic_edit" type="submit" color="primary" variant='outlined' onClick={() => navigate(`/scale_plan/${plan.id}`)} />
            </ListItem>
            {index !== plans.length - 1 && <Divider />}
          </>
          ))}
        </List>
          */ }
}

const MilestonePlanCard: React.FC<PlanCardProps> = ({
  business,
  plans,
}) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { maturityModel, loading } = useMaturityModel();
  const { historyScoreData, isHistoryScoresLoading, historyScoreError } = useBusinessHistoryScores(business.businessId);

  if (loading || isHistoryScoresLoading) return <LoadingAnimation />
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>

  return (
    <Card>
      <CardHeader title={translate('milestone.card_header')} />
      <CardContent>
        <MilestoneTable maturityScoreHistory={historyScoreData} maturityModel={maturityModel} plans={plans} />
      </CardContent>
      <CardActions>
        <Button
          label="actions.milestone_add"
          type="submit"
          color="primary"
          variant='outlined'
          onClick={() => navigate(`/scale_plan/create?businessId=${business.id}`)}
        />
      </CardActions>
    </Card>
  );
};

export default MilestonePlanCard;
