import { DateField, NumberField, SimpleShowLayout, TextField } from 'react-admin';
import { Alert, Box, Button, Card, CardContent, CardHeader, Dialog, Typography } from "@mui/material";
import { CurrencyFormat } from '../../configuration/configuration';
import { BOX_STYLE } from '../MaturityScore';
import { useState } from 'react';
import BusinessGoalsForm from '../../resources/scale_business/BusinessGoalsForm';

export const BusinessInfoCard = (props: any) => {
    const { record } = props;
    const showWarning = !record.businessPotential || !record.budget;
    const [showDialog, setShowDialog] = useState<boolean>(false);


    return (
        /*
        <Box sx={{ ...BOX_STYLE }}>
            <Dialog maxWidth='lg' fullWidth open={showDialog} onClose={() => setShowDialog(false)}>
                <BusinessGoalsForm />
            </Dialog>
            <Typography variant="h5" gutterBottom fontWeight={700}>Business Goals</Typography>

            <Typography variant="body1">
                Linking innovation to clear objectives improve focus on creating solutions that add
                measurable value. Please input your business goals to guide your innovation efforts effectively.
            </Typography>

            <Button onClick={() => setShowDialog(true)} variant='contained'>Define Goals</Button>
        </Box>
        */
        <Box sx={{ ...BOX_STYLE }}>
            <Typography variant="h5" gutterBottom fontWeight={700}>Business Information</Typography>
            {showWarning &&
                <Alert severity="warning">No business case entered.</Alert>
            }
            <SimpleShowLayout  {...props}>
                <TextField resource="business" source="businessModel" />
                {!showWarning && <NumberField resource="business" source="businessPotential" options={CurrencyFormat} />}
                {!showWarning && <NumberField resource="business" source="budget" options={CurrencyFormat} />}
                <DateField resource="business" source="startDate" />
                <DateField resource="business" source="estimatedLaunchDate" />
            </SimpleShowLayout>
        </Box>
    )

}
