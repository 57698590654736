import { FC } from 'react';
import {
    CreateProps, Edit, RecordContext, SaveButton, Toolbar, useNotify, useRedirect,
} from 'react-admin';
import { BusinessExtended } from '../../model/ScaleTypes';
import { BusinessForm } from './BusinessForm';
import { formatArrayForHasura } from '../../util/ScaleUtils';
import { CancelButton } from '../../components/BackButton';

const BusinessEdit: FC<CreateProps> = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const transform = (data: BusinessExtended, record: any) => {
        //@ts-ignore ignore as the data.tags object is stored in different format, ie. format what is needed for hasura for the array
        if (data.tags) data.tags = formatArrayForHasura(data.tags.sort());
        return data;
    }

    const onSuccess = (data: BusinessExtended) => {
        notify(`business.updated`);
        redirect('show', '/api_business', data.id, data)
    }

    const onFailure = (error: any) => {
        console.log("ERROR: ", error);
        notify(`Could not save edit: ${error.message}`)
    };

    const toolbar = <Toolbar>
        <SaveButton />
        <CancelButton />
    </Toolbar>

    return (
        <Edit title={"Project Edit"} mutationOptions={{ onSuccess, onError: onFailure }}
            mutationMode="pessimistic"
            transform={transform as any}
        >
            <BusinessForm toolbar={toolbar} />
        </Edit>
    );
};

export default BusinessEdit;


