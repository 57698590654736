import { defaultTheme } from "react-admin";
import { createTheme } from "@mui/material/styles"
import merge from "lodash/merge"

export const scaleColors =
{
    Brand: "#ff9800",
    Primary: "#5B8C5A",
    Secondary: "#F47052",
    Dark: "#253241",
    White: "#FBFBFF"
};


export const SCALE_THEME = {
    spacing: 8,
    typography: {
        fontFamily: '"Roboto Slab", serif',
        fontSize: 12,
    },
    palette: {
        primary: {
            main: scaleColors.Dark,
            dark: scaleColors.Brand,
        },
        secondary: {
            main: scaleColors.Dark,
        },
        success: {
            main: scaleColors.Primary,
        }
    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                content: { marginRight: '0px', }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    margin: '0px',
                    padding: '0px !important',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    margin: "8px", // (theme) => theme.spacing(4)
                    padding: '0px !important',
                },
                title: { fontWeight: '600' },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    margin: "8px", // (theme) => theme.spacing(4)
                    padding: '0px !important',
                },
                title: { fontWeight: '600' },
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: { width: "unset", },
                item: { padding: "5px !important", },
            }
        },
        MuiTypography: {
            styleOverrides: {
                //                    h6: { fontWeight: '600' }
            }
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    width: "unset", backgroundColor: scaleColors.White
                },
                item: { padding: "5px !important", },
            }
        },
        RaSimpleShowLayout: {
            styleOverrides: {
                root: { padding: "0px" }
            }
        }
    },
}

export const theme = createTheme(
    merge({}, defaultTheme, SCALE_THEME)
)
