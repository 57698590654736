import Plot from 'react-plotly.js'
import { Alert } from '@mui/material';
import { useTranslate } from 'react-admin';


export interface PlotlyChartParams {
  data: Plotly.Data[],
  layout: Partial<Plotly.Layout>,
  error?: string,
}

export interface PlotlyChartProps {
  width?: string,
  height?: string,
  params: PlotlyChartParams,
}

/**
 * Primary UI component for user interaction
 */
export const PlotlyChart: React.FC<PlotlyChartProps> = ({
  width,
  height,
  params,
  ...props
}) => {
  const translate = useTranslate();
  if (params.error) return <Alert severity='warning'>{translate(params.error)}</Alert>
  try {
    return <Plot data={params.data} layout={params.layout} style={{ width, height }} config={{ displayModeBar: false, responsive: false }} />
  } catch (error) {
    return <Alert severity='warning'>{translate("error.message")}</Alert>
  }
};
