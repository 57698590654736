import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Create, Datagrid, DateField, Edit, EditButton, List, ReferenceInput, required, SaveButton, SelectInput, SimpleForm, TextField, TextInput, Toolbar } from 'react-admin';

export function OrganizationUnitForm() {
  return <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>}>
    <Typography>Organizational Unit</Typography>
    <TextInput source="name" validate={required()} />
    <Tooltip title="Permission role is a unique identifier for the organizational unit" placement="right" arrow>
      <div><TextInput source="permissionRole" validate={required()} /></div>
    </Tooltip>
    <ReferenceInput label="Organization" source="organizationId" reference="scale_organization" validate={required()}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </SimpleForm>
}

export function OrganizationUnitEdit() {
  return <Edit><OrganizationUnitForm /></Edit>
}

export function OrganizationUnitCreate() {
  return <Create><OrganizationUnitForm /></Create>
}

export const OrganizationUnitList = () => (
  <List>
    <Datagrid>
      <DateField source="createdAt" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);